import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [currentLang, setCurrentLang] = useState(i18n.language || 'en');

  const languages = {
    en: { flag: '🇬🇧', label: 'English' },
    da: { flag: '🇩🇰', label: 'Dansk' }
  };

  useEffect(() => {
    setCurrentLang(i18n.language || 'en');
  }, [i18n.language]);

  const handleLanguageChange = async (lang) => {
    try {
      await i18n.changeLanguage(lang);
      setCurrentLang(lang);
      localStorage.setItem('i18nextLng', lang);
      setIsOpen(false);
    } catch (error) {
      console.error('[LanguageSelector] Failed to change language:', error);
    }
  };

  return (
    <div className="relative">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="px-3 py-2 rounded-md hover:bg-gray-100"
        aria-label={languages[currentLang]?.label || languages.en.label}
      >
        <span>{languages[currentLang]?.flag || languages.en.flag}</span>
      </button>

      {isOpen && (
        <div className="absolute right-0 mt-2 py-2 w-48 bg-white rounded-md shadow-lg z-50">
          {Object.entries(languages).map(([code, { flag, label }]) => (
            <button
              key={code}
              onClick={() => handleLanguageChange(code)}
              className={`w-full text-left px-4 py-2 hover:bg-gray-100 flex items-center space-x-2 ${
                currentLang === code ? 'bg-gray-50' : ''
              }`}
            >
              <span>{flag}</span>
              <span>{label}</span>
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default LanguageSelector;
