import { showToast, TOAST_TYPES } from '../components/common/ToastManager';
import { fetchFromChatGPT } from './fetchFromChatGPT';
import { FACTOR_CATEGORIES, factors } from '../components/outputs/interview/interviewFactorsList';

const cleanJsonResponse = (content) => {
  return content.replace(/```json\n?|\n?```/g, '');
};

export const fetchQuickAddsInterviewQuestions = async (formData, isInitial = false, customPrompt = '', selectedCategory = '') => {
  const context = `
Company Information:
- Company Name: ${formData.companyData?.name}
- Industry: ${formData.companyData?.industry}
- Company Size: ${formData.companyData?.size || 'N/A'}
- Language: ${formData.roleData?.language || 'en'}

Role Details:
- Job Title: ${formData.roleData?.jobTitle}
- Department: ${formData.roleData?.department}
- Level: ${formData.roleData?.level || 'N/A'}
- Reports To: ${formData.roleData?.reportsTo}

Key Responsibilities:
${formData.responsibilities
  ?.filter(resp => resp && resp.text)
  .map(resp => `- ${resp.text}`)
  .join('\n')}

Key Outcomes:
${formData.outcomes
  ?.filter(outcome => outcome && outcome.text)
  .map(outcome => `- ${outcome.text}`)
  .join('\n')}
`;

  // Get available factors based on selected category
  const availableFactors = selectedCategory 
    ? FACTOR_CATEGORIES[selectedCategory].factors 
    : Object.values(FACTOR_CATEGORIES).flatMap(cat => cat.factors);

  const categoryLabel = selectedCategory 
    ? FACTOR_CATEGORIES[selectedCategory].label
    : 'mixed';

  try {
    const payload = {
      model: "gpt-3.5-turbo",
      messages: [
        {
          role: "system",
          content: `You are an expert HR professional. Generate interview questions following these guidelines:

Question Distribution:
${selectedCategory 
  ? `Generate questions specifically for ${categoryLabel} assessment using these factors: ${availableFactors.join(', ')}`
  : `Generate a balanced mix of questions across all categories:
- Skills (technical competence)
- Performance (problem-solving and achievements)
- Culture (team fit and attitude)`
}

Return a JSON array of objects, each with:
- text: The interview question (10-20 words)
- type: One of [${availableFactors.join(', ')}]
- description: Array of 3-4 key evaluation points

Best Practices:
- Keep questions focused and direct
- Use behavioral and situational formats
- Ensure questions match the selected factor type
- Avoid compound or multi-part questions
- Use simple, straightforward language

Important: Generate all questions AND evaluation criteria in ${formData.roleData?.language === 'da' ? 'Danish' : 'English'}.

Return the response as a plain JSON array without any markdown formatting.`
        },
        {
          role: "user",
          content: `${context}\n\n${customPrompt ? `Focus on: ${customPrompt}` : 'Generate diverse interview questions for this role.'}`
        }
      ],
      temperature: 0.7
    };

    const data = await fetchFromChatGPT(payload, {
      language: formData.roleData?.language || 'en',
      languageName: formData.roleData?.language === 'da' ? 'Danish' : 'English'
    });
    const cleanContent = cleanJsonResponse(data.choices[0].message.content);
    const questions = JSON.parse(cleanContent);

    // Validate that questions use the correct factors
    const validatedQuestions = questions.map(q => {
      // If no specific category selected, ensure balanced distribution
      if (!selectedCategory) {
        const unusedFactors = availableFactors.filter(f => 
          !questions.some(existing => existing.type === f)
        );
        if (unusedFactors.length > 0) {
          q.type = unusedFactors[Math.floor(Math.random() * unusedFactors.length)];
        }
      }
      // Ensure the type is valid for the selected category
      else if (!availableFactors.includes(q.type)) {
        q.type = availableFactors[Math.floor(Math.random() * availableFactors.length)];
      }
      return {
        id: `question_${Date.now()}_${Math.random()}`,
        ...q
      };
    });

    return validatedQuestions;

  } catch (error) {
    console.error('Error fetching quick add questions:', error);
    return [];
  }
}; 