import { fetchFromChatGPT } from './fetchFromChatGPT';

export async function fetchRoleContent(roleContext, language = 'en') {
  try {
    console.log('[fetchRoleContent] Starting with context:', roleContext);
    console.log('[fetchRoleContent] Language:', language);

    const response = await fetchFromChatGPT({
      model: "gpt-3.5-turbo",
      messages: [
        {
          role: "system",
          content: "You are a helpful HR assistant that extracts role information from context. Return the exact field names as specified."
        },
        {
          role: "user",
          content: `Based on this role context, extract and provide values for these exact fields in JSON format:

{
  "jobTitle": "The title of the position (required)",
  "department": "The department or team (required)",
  "reportsTo": "Who this role reports to (if mentioned)",
  "teamSize": "Size of the team (if mentioned)",
  "workModel": "Work model (Remote/Hybrid/On-site)",
  "employmentType": "Type of employment (Full-time/Part-time/Contract)"
}

Role Context: ${roleContext}

Important:
1. Use these exact field names with exact capitalization
2. Return a JSON object with all fields
3. Use empty string "" for missing information
4. Do not add any additional fields
5. Ensure field names match exactly: jobTitle, department, reportsTo, teamSize, workModel, employmentType`
        }
      ],
      temperature: 0.7
    }, {
      language: language,
      languageName: language === 'da' ? 'Danish' : 'English'
    });

    console.log('[fetchRoleContent] Raw GPT response:', response.choices[0].message.content);

    const suggestion = JSON.parse(response.choices[0].message.content);
    console.log('[fetchRoleContent] Parsed suggestion:', suggestion);
    
    // Ensure all fields are present with empty strings as fallback and exact field names
    const result = {
      jobTitle: suggestion.jobTitle || '',
      department: suggestion.department || '',
      reportsTo: suggestion.reportsTo || '',
      teamSize: suggestion.teamSize || '',
      workModel: suggestion.workModel || '',
      employmentType: suggestion.employmentType || ''
    };

    console.log('[fetchRoleContent] Final result with fallbacks:', result);
    
    // Validate required fields
    if (!result.jobTitle || !result.department) {
      console.warn('[fetchRoleContent] Missing required fields:', {
        hasJobTitle: !!result.jobTitle,
        hasDepartment: !!result.department
      });
    }

    // Validate field names match exactly
    const expectedFields = ['jobTitle', 'department', 'reportsTo', 'teamSize', 'workModel', 'employmentType'];
    const actualFields = Object.keys(result);
    const mismatches = expectedFields.filter(field => !actualFields.includes(field));
    if (mismatches.length > 0) {
      console.warn('[fetchRoleContent] Field name mismatches:', mismatches);
    }

    return result;
  } catch (error) {
    console.error('[fetchRoleContent] Error:', error);
    throw error;
  }
}
