import React, { createContext, useContext, useEffect, useState } from 'react';
import { useSupabaseClient, useUser } from '@supabase/auth-helpers-react';

export enum FeatureType {
  EXPORT = 'export',
  PRO_MODE = 'pro_mode',
  API_ACCESS = 'api_access'
}

interface SubscriptionStatus {
  isActive: boolean;
  plan: string | null;
  features: string[];
  subscriptionDetails?: {
    status: string;
    current_period_end: number;
    cancel_at_period_end: boolean;
    plan_name: string;
    price_id: string;
  };
}

interface SubscriptionContextType {
  status: SubscriptionStatus | null;
  isLoading: boolean;
  error: Error | null;
  checkFeature: (feature: string) => boolean;
  refreshStatus: () => Promise<void>;
}

const SubscriptionContext = createContext<SubscriptionContextType | undefined>(undefined);

export function SubscriptionProvider({ children }: { children: React.ReactNode }) {
  const [status, setStatus] = useState<SubscriptionStatus | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const supabase = useSupabaseClient();
  const user = useUser();

  const checkSubscriptionStatus = async () => {
    try {
      setIsLoading(true);
      setError(null);

      const { data: { session } } = await supabase.auth.getSession();
      if (!session) {
        throw new Error('No active session');
      }

      const response = await fetch(
        `${process.env.REACT_APP_SUPABASE_URL}/functions/v1/check-subscription-status`,
        {
          headers: {
            Authorization: `Bearer ${session.access_token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (!response.ok) {
        throw new Error('Failed to fetch subscription status');
      }

      const data = await response.json();
      setStatus(data);
    } catch (err) {
      setError(err instanceof Error ? err : new Error('Unknown error'));
      console.error('Error checking subscription:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const checkFeature = (feature: string): boolean => {
    return status?.features?.includes(feature) ?? false;
  };

  useEffect(() => {
    if (user) {
      checkSubscriptionStatus();
    } else {
      setStatus(null);
    }
  }, [user]);

  return (
    <SubscriptionContext.Provider
      value={{
        status,
        isLoading,
        error,
        checkFeature,
        refreshStatus: checkSubscriptionStatus,
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  );
}

export function useSubscription() {
  const context = useContext(SubscriptionContext);
  if (context === undefined) {
    throw new Error('useSubscription must be used within a SubscriptionProvider');
  }
  return context;
} 