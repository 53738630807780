export const STRIPE_PRICE_IDS = {
    monthly: {
      toolbox: 'price_1QUporLqqcw8AbjEvVnR6zdx',     // Toolbox Monthly $19
      full_access: 'price_1QUposLqqcw8AbjE8wu9x2J6'  // PredictHire Monthly $49
    },
    annual: {
      toolbox: 'price_1QUposLqqcw8AbjEL2uWV80f',     // Toolbox Annual $149
      full_access: 'price_1QUposLqqcw8AbjEkdLC4jbr'  // PredictHire Annual $399
    }
  };

export const PLANS = {
  monthly: [
    {
      id: 'toolbox',
      name: 'Toolbox',
      price: 19,
      description: 'Access to toolbox features',
      features: [
        'Basic toolbox features',
        'Monthly flexibility',
        'Email support'
      ]
    },
    {
      id: 'full_access',
      name: 'Full Access',
      price: 49,
      description: 'Full access to all PredictHire features',
      features: [
        'All toolbox features',
        'Advanced analytics',
        'Priority support',
        'Custom integrations'
      ]
    }
  ],
  annual: [
    {
      id: 'toolbox',
      name: 'Toolbox',
      price: 149,
      description: 'Access to toolbox features',
      features: [
        'Basic toolbox features',
        'Save 37% annually',
        'Email support'
      ]
    },
    {
      id: 'full_access',
      name: 'Full Access',
      price: 399,
      description: 'Full access to all PredictHire features',
      features: [
        'All toolbox features',
        'Save 32% annually',
        'Advanced analytics',
        'Priority support',
        'Custom integrations'
      ]
    }
  ]
}; 