import React from 'react';
import { ChevronLeft, ChevronRight, Sparkles } from 'lucide-react';
import { useTranslation } from 'react-i18next';

// Define loader URL as a constant
const LOADER_URL = `${process.env.PUBLIC_URL}/loader.gif`;

const QuickAddPanel = ({
  title,
  isCollapsed,
  onCollapse,
  isLoading,
  items = [],
  onItemClick,
  onReload,
  renderItem,
  translationPrefix = 'quickAdd'
}) => {
  const { t } = useTranslation();

  return (
    <div className={`
      bg-gray-50 rounded-lg transition-all duration-300
      ${isCollapsed 
        ? 'w-12 self-start ml-auto hover:bg-gray-100 cursor-pointer' 
        : 'w-full p-4'
      }`}
      onClick={() => isCollapsed && onCollapse(false)}
    >
      <div className={`flex items-center justify-between ${isCollapsed ? 'h-12' : 'mb-3'}`}>
        <h3 className={`text-xs font-medium text-gray-700 ${isCollapsed ? 'hidden' : ''}`}>
          {title || t(`${translationPrefix}.title`)}
        </h3>
        <button 
          onClick={(e) => {
            e.stopPropagation();
            onCollapse(!isCollapsed);
          }}
          className={`text-gray-500 hover:text-gray-700 ${isCollapsed ? 'm-auto' : ''}`}
        >
          {isCollapsed ? 
            <ChevronLeft className="h-4 w-4" /> : 
            <ChevronRight className="h-4 w-4" />
          }
        </button>
      </div>
      
      {!isCollapsed && (
        <>
          {isLoading ? (
            <div className="flex justify-center">
              <img src={LOADER_URL} alt={t('common.loading')} className="h-8 w-8" />
            </div>
          ) : items.length > 0 ? (
            <div className="space-y-2">
              {items.map((item, index) => renderItem(item, index))}
            </div>
          ) : (
            <p className="text-sm text-gray-500">
              {isLoading ? t(`${translationPrefix}.loading`) : t(`${translationPrefix}.noSuggestions`)}
            </p>
          )}

          <button
            onClick={onReload}
            disabled={isLoading}
            className="flex items-center gap-1.5 text-blue-600 hover:text-blue-800 mt-4 text-sm"
          >
            {isLoading && <img src={LOADER_URL} alt="" className="h-3.5 w-3.5" />}
            {!isLoading && <Sparkles className="h-3.5 w-3.5" />}
            {isLoading ? t(`${translationPrefix}.reloadButtonLoading`) : t(`${translationPrefix}.reloadButtonText`)}
          </button>
        </>
      )}
    </div>
  );
};

export default QuickAddPanel; 