import React, { useState } from 'react';
import { Download, Loader } from 'lucide-react';
import { useFormContext } from '../../../contexts/FormContext';
import { showToast, TOAST_TYPES } from '../../common/ToastManager';
import { Document, Packer, Paragraph, HeadingLevel } from 'docx';
import { useTranslation } from 'react-i18next';

const ExportDocxRole = () => {
  const { t } = useTranslation();
  const { formData } = useFormContext();
  const [isExporting, setIsExporting] = useState(false);

  const formatTimeframe = (timeframe) => {
    const timeframeMap = {
      'first_week': 'First Week',
      'first_month': 'First Month',
      'three_months': '3 Months',
      'six_months': '6 Months',
      'first_three_months': '3 Months',
      'first_six_months': '6 Months',
      'yearly': '1 Year'
    };
    return timeframeMap[timeframe] || timeframe;
  };

  const formatTimelines = (timelines) => {
    if (!timelines?.steps || timelines.steps.length === 0) {
      return ['No timeline entries yet'];
    }

    return timelines.steps
      .sort((a, b) => new Date(a.date) - new Date(b.date))
      .map((step, index) => {
        const date = step.date 
          ? new Date(step.date).toLocaleDateString()
          : 'Date not set';
        const duration = step.duration ? ` (${step.duration})` : '';
        return `${index + 1}. ${step.text}${duration} - ${date}`;
      });
  };

  const handleExportDocx = async () => {
    setIsExporting(true);
    try {
      const doc = new Document({
        sections: [{
          properties: {},
          children: [
            new Paragraph({
              text: t('export.docx.title'),
              heading: HeadingLevel.HEADING_1,
            }),
            new Paragraph({
              text: `${t('export.docx.generated')}: ${new Date().toLocaleString()}`,
              spacing: { after: 400 }
            }),

            // Company Information
            new Paragraph({
              text: t('export.docx.companyInfo'),
              heading: HeadingLevel.HEADING_2,
            }),
            new Paragraph({ text: `${t('company.name')}: ${formData.companyData?.name || 'N/A'}` }),
            new Paragraph({ text: `${t('company.industry')}: ${formData.companyData?.industry || 'N/A'}` }),
            new Paragraph({ text: `${t('company.description')}: ${formData.companyData?.description || 'N/A'}` }),
            new Paragraph({ 
              text: `${t('company.culture')}: ${formData.companyData?.culture || 'N/A'}`,
              spacing: { after: 400 }
            }),

            // Role Details
            new Paragraph({
              text: t('export.docx.roleDetails'),
              heading: HeadingLevel.HEADING_2,
            }),
            new Paragraph({ text: `${t('role.jobTitle')}: ${formData.roleData?.jobTitle || 'N/A'}` }),
            new Paragraph({ text: `${t('role.department')}: ${formData.roleData?.department || 'N/A'}` }),
            new Paragraph({ text: `${t('role.reportsTo')}: ${formData.roleData?.reportsTo || 'N/A'}` }),
            new Paragraph({ text: `${t('role.workModel')}: ${formData.roleData?.workModel || 'N/A'}` }),
            new Paragraph({ text: `${t('role.employmentType')}: ${formData.roleData?.employmentType || 'N/A'}` }),
            new Paragraph({ 
              text: `${t('role.teamSize')}: ${formData.roleData?.teamSize || 'N/A'}`,
              spacing: { after: 400 }
            }),

            // Responsibilities
            new Paragraph({
              text: t('export.docx.responsibilities'),
              heading: HeadingLevel.HEADING_2,
            }),
            ...Object.values(formData.responsibilities || {})
              .filter(resp => resp && resp.text)
              .map((resp, index) => new Paragraph({ 
                text: `${index + 1}. ${resp.text}`,
                spacing: { after: 200 }
              })),

            // Key Outcomes
            new Paragraph({
              text: t('export.docx.outcomes'),
              heading: HeadingLevel.HEADING_2,
            }),
            ...Object.values(formData.outcomes || {})
              .filter(outcome => outcome && outcome.text)
              .map((outcome, index) => new Paragraph({ 
                text: `${index + 1}. ${outcome.text}\n${t('common.timeframe')}: ${formatTimeframe(outcome.timeframe || t('common.notSpecified'))}`,
                spacing: { after: 200 }
              })),

            // Timeline
            new Paragraph({
              text: t('export.docx.timeline'),
              heading: HeadingLevel.HEADING_2,
            }),
            ...formatTimelines(formData.timelines).map(line => 
              new Paragraph({ 
                text: line,
                spacing: { after: 200 }
              })
            ),

            // Footer
            new Paragraph({
              text: t('export.docx.footer'),
              spacing: { before: 400 },
              alignment: "center"
            })
          ]
        }]
      });

      const blob = await Packer.toBlob(doc);
      const timestamp = new Date().toISOString().split('T')[0];
      const filename = `role-design_${timestamp}.docx`;

      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);

      showToast(t('export.toasts.exportSuccess'), TOAST_TYPES.SUCCESS);
    } catch (error) {
      console.error('Error exporting role data:', error);
      showToast(t('export.toasts.exportError'), TOAST_TYPES.ERROR);
    } finally {
      setIsExporting(false);
    }
  };

  return (
    <button
      onClick={handleExportDocx}
      disabled={isExporting}
      className={`
        flex items-center gap-2 px-6 py-3 rounded-lg 
        ${isExporting 
          ? 'bg-gray-200 cursor-not-allowed' 
          : 'bg-blue-600 hover:bg-blue-700'} 
        text-white font-medium transition-colors
      `}
    >
      {isExporting ? (
        <>
          <Loader className="h-5 w-5 animate-spin" />
          {t('export.buttons.exporting')}
        </>
      ) : (
        <>
          <Download className="h-5 w-5" />
          {t('export.buttons.downloadDOCX')}
        </>
      )}
    </button>
  );
};

export default ExportDocxRole; 