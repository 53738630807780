import React, { useState } from 'react';
import { Download, Loader } from 'lucide-react';
import { useFormContext } from '../../../contexts/FormContext';
import { showToast, TOAST_TYPES } from '../../common/ToastManager';
import { useTranslation } from 'react-i18next';

const ExportJsonRole = () => {
  const { t } = useTranslation();
  const { formData } = useFormContext();
  const [isExporting, setIsExporting] = useState(false);

  const handleExportJson = async () => {
    setIsExporting(true);
    try {
      const dataToExport = {
        version: "1.0",
        timestamp: new Date().toISOString(),
        data: {
          company: formData.companyData,
          role: formData.roleData,
          timelines: formData.timelines,
          outcomes: formData.outcomes,
          responsibilities: formData.responsibilities,
        }
      };

      const blob = new Blob([JSON.stringify(dataToExport, null, 2)], { 
        type: 'application/json' 
      });
      
      const timestamp = new Date().toISOString().split('T')[0];
      const filename = `role-design_${timestamp}.json`;

      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);

      showToast(t('export.toasts.exportSuccess'), TOAST_TYPES.SUCCESS);
    } catch (error) {
      console.error('Error exporting role data:', error);
      showToast(t('export.toasts.exportError'), TOAST_TYPES.ERROR);
    } finally {
      setIsExporting(false);
    }
  };

  return (
    <button
      onClick={handleExportJson}
      disabled={isExporting}
      className={`
        flex items-center gap-2 px-6 py-3 rounded-lg 
        ${isExporting 
          ? 'bg-gray-200 cursor-not-allowed' 
          : 'bg-blue-600 hover:bg-blue-700'} 
        text-white font-medium transition-colors
      `}
    >
      {isExporting ? (
        <>
          <Loader className="h-5 w-5 animate-spin" />
          {t('export.buttons.exporting')}
        </>
      ) : (
        <>
          <Download className="h-5 w-5" />
          {t('export.buttons.downloadJSON')}
        </>
      )}
    </button>
  );
};

export default ExportJsonRole; 