import React from 'react';
import { Sparkles } from 'lucide-react';
import { useTranslation } from 'react-i18next';

const ProModeSection = ({ onEnableProMode }) => {
  const { t } = useTranslation();

  return (
    <div className="max-w-5xl mx-auto mt-4 p-4 bg-blue-50 rounded-lg border border-blue-100">
      <div className="flex items-center justify-between">
        <p className="text-sm text-blue-700">
          {t('pro.upgradeMessage')}
        </p>
        <button
          onClick={onEnableProMode}
          className="flex items-center gap-2 ml-4 px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 transition-colors whitespace-nowrap"
        >
          <Sparkles className="h-4 w-4" />
          {t('pro.enableButton')}
        </button>
      </div>
    </div>
  );
};

export default ProModeSection; 