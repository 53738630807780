import { showToast, TOAST_TYPES } from '../components/common/ToastManager';

export const ROLE_DATA_STRUCTURE = {
    companyData: {
      required: ['name', 'description'],
      optional: ['industry', 'culture', 'companyUrl'],
      defaultValue: {
        name: '',
        industry: '',
        description: '',
        culture: '',
        companyUrl: ''
      }
    },
    roleData: {
      required: ['jobTitle', 'department'],
      optional: ['reportsTo', 'teamSize', 'workModel', 'employmentType', 'roleContext', 'salary', 'currency', 'language'],
      defaultValue: {
        jobTitle: '',
        department: '',
        reportsTo: '',
        teamSize: '',
        workModel: '',
        employmentType: '',
        roleContext: '',
        salary: '',
        currency: 'EUR',
        language: ''
      }
    },
    timelines: {
      required: ['targetStartDate'],
      structure: {
        steps: {
          required: ['text', 'date'],
          optional: ['duration'],
          isArray: true
        }
      },
      defaultValue: {
        targetStartDate: '',
        steps: []
      }
    },
    responsibilities: {
      isArray: true,
      structure: {
        required: ['text']
      },
      defaultValue: []
    },
    outcomes: {
      isArray: true,
      structure: {
        required: ['text', 'timeframe']
      },
      defaultValue: []
    }
  };
  
  // For API requests (used in fetchQuickAdds)
  export const formatDataForAPI = (formData) => {
    return {
      company: {
        name: formData.companyData?.name || 'Not Specified',
        industry: formData.companyData?.industry || 'Not Specified',
        description: formData.companyData?.description || 'Not Specified',
        culture: formData.companyData?.culture || 'Not Specified'
      },
      role: {
        jobTitle: formData.roleData?.jobTitle || 'Not Specified',
        department: formData.roleData?.department || 'Not Specified',
        reportsTo: formData.roleData?.reportsTo || 'Not Specified',
        teamSize: formData.roleData?.teamSize || 'Not Specified',
        workModel: formData.roleData?.workModel || 'Not Specified',
        employmentType: formData.roleData?.employmentType || 'Not Specified',
        roleContext: formData.roleData?.roleContext || 'Not Specified',
        language: formData.roleData?.language || '',
        salary: formData.roleData?.salary || '',
        currency: formData.roleData?.currency || 'EUR'
      },
      timelines: {
        targetStartDate: formData.timelines?.targetStartDate || 'Not Specified'
      },
      responsibilities: Array.isArray(formData.responsibilities)
        ? formData.responsibilities
            .filter(r => r && (typeof r === 'string' || r.text)) // Filter out invalid entries
            .map(r => {
              // Handle both string and object formats
              const text = typeof r === 'string' ? r : r.text;
              return { text: text || 'Not Specified' };
            })
        : [],
      outcomes: Array.isArray(formData.outcomes)
        ? formData.outcomes
            .filter(o => o && (typeof o === 'string' || o.text))
            .map(o => ({
              text: typeof o === 'string' ? o : (o.text || 'Not Specified'),
              timeframe: o.timeframe || 'Not Specified'
            }))
        : []
    };
  };
  
  export const getInitialState = () => ({
    companyData: {
      name: '',
      industry: '',
      description: '',
      culture: '',
      companyUrl: ''
    },
    roleData: {
      jobTitle: '',
      department: '',
      reportsTo: '',
      teamSize: '',
      workModel: '',
      employmentType: '',
      roleContext: '',
      salary: '',
      currency: 'EUR',
      language: ''
    },
    timelines: {
      targetStartDate: '',
      steps: []
    },
    responsibilities: [],
    outcomes: [],
    outputs: {
      jobPost: '',
      skillAssessment: '',
      timeline: ''
    },
    interviewQuestions: []
  });