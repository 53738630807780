import React from 'react';
import { AlertTriangle } from 'lucide-react';
import { getComplianceTip, getHighlightedStopWords } from '../utils/complianceValidation';
import { useTranslation } from 'react-i18next';

const ComplianceWarnings = ({ 
  text,
  questionId,
  activeTooltip,
  setActiveTooltip 
}) => {
  const { t } = useTranslation();
  const complianceTip = getComplianceTip(text);
  const highlightedWords = getHighlightedStopWords(text);

  if (!complianceTip.title || !highlightedWords.length) {
    return null;
  }

  return (
    <div className="relative">
      <div 
        onClick={() => setActiveTooltip(activeTooltip === `compliance-${questionId}` ? null : `compliance-${questionId}`)}
        className="text-xs px-1.5 py-0.5 rounded bg-red-50 text-red-600 cursor-help hover:bg-red-100"
      >
        <AlertTriangle className="h-3.5 w-3.5 inline-block mr-1" />
        {t('interviewQuestions.compliance.issue')}
      </div>
      {activeTooltip === `compliance-${questionId}` && (
        <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-3 py-2 bg-gray-900 text-white text-xs rounded whitespace-nowrap">
          <div className="font-medium mb-1">{complianceTip.title}</div>
          <div className="text-gray-300">{complianceTip.tip}</div>
          <div className="mt-1 text-gray-400 italic text-[10px]">{complianceTip.example}</div>
          <div className="mt-1 text-red-400 text-[10px]">
            {t('interviewQuestions.compliance.found')}: {highlightedWords.join(', ')}
          </div>
        </div>
      )}
    </div>
  );
};

export default ComplianceWarnings; 