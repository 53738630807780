import React, { createContext, useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ROLE_DATA_STRUCTURE, getInitialState } from '../utils/dataStructure';
import { showToast, TOAST_TYPES } from '../components/common/ToastManager';

const FormContext = createContext();
const STORAGE_KEY = 'roleDesignerFormData';
const PRO_MODE_KEY = 'roleDesignerProMode';

const hasProContent = (formData) => {
  // Check if there's any content in pro features
  const hasTimelines = formData.timelines?.steps?.length > 0;
  const hasResponsibilities = formData.responsibilities?.length > 0;
  const hasOutcomes = formData.outcomes?.length > 0;
  const hasInterviewQuestions = formData.interviewQuestions?.length > 0;

  return hasTimelines || hasResponsibilities || hasOutcomes || hasInterviewQuestions;
};

export const FormProvider = ({ children }) => {
  const { i18n } = useTranslation();
  const [formData, setFormData] = useState(() => {
    const savedData = localStorage.getItem(STORAGE_KEY);
    const parsedData = savedData ? JSON.parse(savedData) : getInitialState();
    return parsedData;
  });

  const [isProMode, setIsProMode] = useState(() => {
    // Check localStorage first
    const savedProMode = localStorage.getItem(PRO_MODE_KEY);
    const savedProModeValue = savedProMode ? JSON.parse(savedProMode) : false;
    
    // Check if viewing shared role
    const isViewingSharedRole = window.location.pathname.includes('/roles/');
    
    // Check if there's pro content in the form data
    const savedData = localStorage.getItem(STORAGE_KEY);
    const parsedData = savedData ? JSON.parse(savedData) : getInitialState();
    const hasExistingProContent = hasProContent(parsedData);
    
    // Enable pro mode if any condition is true
    return savedProModeValue || isViewingSharedRole || hasExistingProContent;
  });

  const toggleProMode = () => {
    setIsProMode(prev => {
      const newValue = !prev;
      
      // If trying to disable pro mode while having pro content, show warning
      if (!newValue && hasProContent(formData)) {
        showToast(
          'Warning: Pro content will be hidden but not deleted when pro mode is disabled', 
          TOAST_TYPES.WARNING
        );
      }
      
      localStorage.setItem(PRO_MODE_KEY, JSON.stringify(newValue));
      return newValue;
    });
  };

  const setProModeEnabled = (enabled) => {
    // If trying to disable pro mode while having pro content, show warning
    if (!enabled && hasProContent(formData)) {
      showToast(
        'Warning: Pro content will be hidden but not deleted when pro mode is disabled', 
        TOAST_TYPES.WARNING
      );
    }
    
    setIsProMode(enabled);
    localStorage.setItem(PRO_MODE_KEY, JSON.stringify(enabled));
  };

  useEffect(() => {
    localStorage.setItem(PRO_MODE_KEY, JSON.stringify(isProMode));
  }, [isProMode]);

  useEffect(() => {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(formData));
  }, [formData]);

  useEffect(() => {
    const handleUrlChange = () => {
      const isViewingSharedRole = window.location.pathname.includes('/roles/');
      const savedProMode = localStorage.getItem(PRO_MODE_KEY);
      const wasProMode = savedProMode ? JSON.parse(savedProMode) : false;
      
      if (isViewingSharedRole || wasProMode) {
        setIsProMode(true);
      }
    };

    window.addEventListener('popstate', handleUrlChange);
    
    handleUrlChange();

    return () => window.removeEventListener('popstate', handleUrlChange);
  }, []);

  useEffect(() => {
    if (formData.roleData.language !== i18n.language) {
      console.log('[FormContext] Language changed:', {
        from: formData.roleData.language,
        to: i18n.language,
        hasQuestions: formData.interviewQuestions?.length > 0
      });

      setFormData(prev => ({
        ...prev,
        roleData: {
          ...prev.roleData,
          language: i18n.language
        },
        // Preserve interview questions during language change
        interviewQuestions: prev.interviewQuestions || []
      }));
    }
  }, [i18n.language]);

  useEffect(() => {
    // If there are interview questions and a language is set, update the language
    if (formData.interviewQuestions?.length > 0 && formData.roleData.language) {
      const updatedQuestions = formData.interviewQuestions.map(question => ({
        ...question,
        language: formData.roleData.language
      }));
      setFormData(prev => ({
        ...prev,
        interviewQuestions: updatedQuestions
      }));
    }
  }, [formData.interviewQuestions, formData.roleData.language]);

  const updateCompanyData = (companyData) => {
    setFormData(prev => ({
      ...prev,
      companyData
    }));
  };

  const updateRoleData = (roleData) => {
    setFormData(prev => ({
      ...prev,
      roleData
    }));
  };

  const updateTimelines = (updates) => {
    setFormData(prev => ({
      ...prev,
      timelines: {
        ...prev.timelines,
        ...updates
      }
    }));
  };

  const updateResponsibilities = (responsibilities) => {
    // Only update if there's an actual change
    const currentResponsibilities = formData.responsibilities || [];
    const newResponsibilities = Array.isArray(responsibilities) ? responsibilities : [];
    
    // Compare the current and new responsibilities
    const hasChanged = JSON.stringify(currentResponsibilities) !== JSON.stringify(newResponsibilities);
    
    if (hasChanged) {
      // Ensure responsibilities is always an array and has the correct structure
      const validatedResponsibilities = newResponsibilities.map(resp => {
        // If it's a string, convert to object structure
        if (typeof resp === 'string') {
          return { id: `resp_${Date.now()}_${Math.random()}`, text: resp };
        }
        // If it's an object, ensure it has id and text
        return {
          id: resp.id || `resp_${Date.now()}_${Math.random()}`,
          text: resp.text || ''
        };
      });

      setFormData(prev => ({
        ...prev,
        responsibilities: validatedResponsibilities
      }));
    }
  };

  const updateOutcomes = (outcomes) => {
    setFormData(prev => ({
      ...prev,
      outcomes
    }));
  };

  const updateField = (section, field, value) => {
    setFormData(prev => ({
      ...prev,
      [section]: {
        ...prev[section],
        [field]: value
      }
    }));
  };

  const updateOutput = (type, content) => {
    console.log('[FormContext] Updating output with language:', {
      type,
      language: i18n.language
    });
    
    // If content is already in the new format, use it as is
    const outputContent = content?.content ? content : {
      content: content,
      language: i18n.language,
      timestamp: new Date().toISOString()
    };
    
    setFormData(prev => ({
      ...prev,
      outputs: {
        ...prev.outputs,
        [type]: outputContent
      }
    }));
  };

  const updateInterviewQuestions = (questions, context = null) => {
    console.log('[FormContext] Updating interview questions:', {
      count: questions?.length,
      language: i18n.language,
      context
    });
    
    // Only update if we have questions or explicit context
    if (questions?.length > 0 || context) {
      setFormData(prev => ({
        ...prev,
        interviewQuestions: questions
      }));
    }
  };

  const canShareRole = () => {
    const hasCompanyName = formData.companyData && 
                          typeof formData.companyData.name === 'string' && 
                          formData.companyData.name.trim() !== '';
                          
    const hasJobTitle = formData.roleData && 
                       typeof formData.roleData.jobTitle === 'string' && 
                       formData.roleData.jobTitle.trim() !== '';

    console.log('Detailed shareability check:', {
      formDataExists: !!formData,
      companyDataExists: !!formData.companyData,
      roleDataExists: !!formData.roleData,
      companyNameType: typeof formData.companyData?.name,
      jobTitleType: typeof formData.roleData?.jobTitle,
      companyNameValue: formData.companyData?.name,
      jobTitleValue: formData.roleData?.jobTitle,
      hasCompanyName,
      hasJobTitle
    });
    
    return Boolean(hasCompanyName && hasJobTitle);
  };

  const getOutputContent = (type) => {
    const output = formData.outputs?.[type];
    // Handle both new and old format
    return output?.content || output || '';
  };

  const value = {
    formData,
    isProMode,
    toggleProMode,
    setProModeEnabled,
    updateField,
    updateCompanyData,
    updateRoleData,
    updateTimelines,
    updateResponsibilities,
    updateOutcomes,
    updateOutput,
    updateInterviewQuestions,
    canShareRole,
    getOutputContent,
  };

  return (
    <FormContext.Provider value={value}>
      {children}
    </FormContext.Provider>
  );
};

export const useFormContext = () => {
  const context = useContext(FormContext);
  if (!context) {
    throw new Error('useFormContext must be used within a FormProvider');
  }
  return context;
};
