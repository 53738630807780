import React, { useState } from 'react';
import { RefreshCw } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import QuickAddItem from './QuickAddItem';
import { FACTOR_CATEGORIES } from '../interviewFactorsList';

// Define loader URL as a constant
const LOADER_URL = `${process.env.PUBLIC_URL}/loader.gif`;

const QuickAddPanel = ({
  questions,
  isLoading,
  onQuickAdd,
  onReload
}) => {
  const [customPrompt, setCustomPrompt] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const { t } = useTranslation();

  return (
    <div className="relative">
      <div className="bg-gray-50 p-4 rounded-lg">
        <h3 className="text-xs font-medium text-gray-700 mb-3">{t('quickAdd.title')}</h3>
        
        <div className="space-y-3">
          <select
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
            className="w-full text-sm px-3 py-2 border rounded-md"
            disabled={isLoading}
          >
            <option value="">{t('quickAdd.selectCategory')}</option>
            {Object.entries(FACTOR_CATEGORIES).map(([categoryId, category]) => (
              <option key={categoryId} value={categoryId}>
                {category.label}
              </option>
            ))}
          </select>

          <div>
            <input
              type="text"
              value={customPrompt}
              onChange={(e) => setCustomPrompt(e.target.value)}
              placeholder={t('quickAdd.topicPlaceholder')}
              className="w-full text-sm px-3 py-2 border rounded-md"
              disabled={isLoading}
            />
            <button
              onClick={() => {
                onReload(customPrompt, selectedCategory);
                setCustomPrompt('');
              }}
              disabled={isLoading}
              className={`w-full mt-2 flex items-center justify-center gap-1.5 px-3 py-1.5 text-sm 
                ${selectedCategory ? `${FACTOR_CATEGORIES[selectedCategory]?.bgColor} ${FACTOR_CATEGORIES[selectedCategory]?.textColor} hover:opacity-90` : 
                'bg-blue-50 text-blue-600 hover:bg-blue-100'} rounded-md transition-colors
                ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
            >
              {isLoading ? (
                <img src={LOADER_URL} alt="" className="h-4 w-4" />
              ) : (
                <RefreshCw className="h-3.5 w-3.5" />
              )}
              {isLoading ? t('quickAdd.reloadButtonLoading') : t('quickAdd.generateButton')}
            </button>
          </div>
        </div>
      </div>

      {questions.length > 0 && !isLoading && (
        <div className="space-y-1 mt-4">
          {questions.map((question) => (
            <QuickAddItem 
              key={question.id}
              question={question}
              onClick={() => onQuickAdd(question)}
            />
          ))}
        </div>
      )}

      {/* Loading overlay */}
      {isLoading && (
        <div className="absolute inset-0 bg-white bg-opacity-75 flex items-center justify-center">
          <img src={LOADER_URL} alt={t('quickAdd.loading')} className="h-8 w-8" />
        </div>
      )}
    </div>
  );
};

export default QuickAddPanel; 