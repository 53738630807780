// File: react-app/src/components/RoleOverview.jsx
import React, { useState, useEffect } from 'react';
import { Briefcase, Sparkles, Building, Plus, X } from 'lucide-react';
import { APP_BASENAME } from '../App';
import { 
  Card, 
  CardHeader, 
  CardTitle, 
  CardContent, 
  CardSubHeader,
  GradientButton,
  hasAllRequiredFields 
} from './CardComponents';
import { useFormContext } from '../contexts/FormContext';
import { showToast, TOAST_TYPES } from './common/ToastManager';
import { LANGUAGES, CURRENCIES, POPULAR_CURRENCIES, getCurrencyForLanguage } from '../utils/constants';
import { useTranslation } from 'react-i18next';
import { fetchFromChatGPT } from '../utils/fetchFromChatGPT';
import { fetchRoleContent } from '../utils/fetchRoleContent';

const RoleOverview = () => {
  const { formData, updateField } = useFormContext();
  const [isLoading, setIsLoading] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const roleData = formData?.roleData || {};
  const { t, i18n } = useTranslation();

  const requiredFields = ['jobTitle', 'department', 'roleContext'];

  const [languageSearch, setLanguageSearch] = useState('');
  const [isLanguageDropdownOpen, setIsLanguageDropdownOpen] = useState(false);

  // Sync language with i18n language on mount and language changes
  useEffect(() => {
    const currentLang = i18n.language;
    
    // Only update if we have role data and the languages don't match
    if (roleData && currentLang) {
      // Don't trigger update if languages already match
      if (roleData.language !== currentLang) {
        console.log('[RoleOverview] Updating language:', { from: roleData.language, to: currentLang });
        updateField('roleData', 'language', currentLang);
        
        // Only update currency if no salary is set
        if (!roleData.salary) {
          const newCurrency = getCurrencyForLanguage(currentLang);
          updateField('roleData', 'currency', newCurrency);
        }
      }
    }
  }, [i18n.language, roleData?.language]); // Only depend on language changes

  const toggleField = (fieldName) => {
    updateField('roleData', fieldName, roleData[fieldName] === null ? '' : null);
  };

  const handleFetchRoleInfo = async () => {
    setIsLoading(true);
    try {
      showToast(t('roleOverview.toasts.analyzing'), TOAST_TYPES.INFO);
      
      // Use i18n.language directly
      const currentLanguage = i18n.language;
      const languageObj = LANGUAGES.find(lang => lang.code === currentLanguage);
      const languageName = languageObj ? languageObj.name : 'English';

      console.log('[RoleOverview] Starting fetch with:', {
        language: currentLanguage,
        languageName,
        roleContext: roleData.roleContext
      });

      // Call GPT with language options
      const data = await fetchRoleContent(roleData.roleContext, currentLanguage);
      console.log('[RoleOverview] Received data:', data);

      if (!data) {
        throw new Error('No data received from fetchRoleContent');
      }

      // Update all fields at once
      const updates = {};
      Object.entries(data).forEach(([key, value]) => {
        if (value !== undefined) {
          updates[key] = value || '';
        }
      });

      console.log('[RoleOverview] Applying updates:', updates);
      
      // Apply all updates at once
      Object.entries(updates).forEach(([key, value]) => {
        updateField('roleData', key, value);
      });

      showToast(t('roleOverview.toasts.analyzeSuccess'), TOAST_TYPES.SUCCESS);
      setIsExpanded(true);

    } catch (error) {
      console.error('[RoleOverview] Error fetching role info:', error);
      showToast(t('roleOverview.toasts.analyzeError'), TOAST_TYPES.ERROR);
    } finally {
      setIsLoading(false);
    }
  };

  const formatSalaryInput = (value, currency) => {
    if (!value) return value;
    
    // Remove any existing formatting (dots and commas)
    const numberOnly = value.replace(/[^0-9-]/g, '');
    
    // Define currencies that use comma as decimal separator and dot as thousand separator
    const useEuropeanFormat = ['EUR', 'DKK', 'NOK', 'SEK', 'CHF'].includes(currency);
    
    // Format the number with appropriate thousand separator
    if (useEuropeanFormat) {
      // Use dots for thousands (European format)
      return numberOnly.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    } else {
      // Use commas for thousands (US/UK format)
      return numberOnly.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
  };

  // Filter languages based on search
  const filteredLanguages = LANGUAGES.filter(lang => 
    lang.name.toLowerCase().includes(languageSearch.toLowerCase()) ||
    lang.code.toLowerCase().includes(languageSearch.toLowerCase())
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isLanguageDropdownOpen && !event.target.closest('.language-dropdown')) {
        setIsLanguageDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [isLanguageDropdownOpen]);

  const getFieldTranslation = (fieldName) => {
    // Special case for additionalLanguages
    if (fieldName === 'additionalLanguages') {
      return t('roleOverview.additionalLanguages');
    }
    return t(`roleOverview.fields.${fieldName.toLowerCase()}.label`) || fieldName;
  };

  // Migrate aboutUrl from roleData to companyData if needed
  useEffect(() => {
    if (roleData.aboutUrl) {
      // Move the value to companyData
      updateField('companyData', 'aboutUrl', roleData.aboutUrl);
      // Clear it from roleData
      updateField('roleData', 'aboutUrl', null);
    }
  }, []); // Only run once on mount

  return (
    <Card className="max-w-5xl mx-auto">
      <CardHeader 
        data={roleData}
        requiredFields={requiredFields}
        isExpanded={isExpanded}
        onClick={() => setIsExpanded(!isExpanded)}
        inputSection={
          <div className="flex items-center gap-2">
            <div className="flex items-center gap-2 relative w-[250px]">
              <textarea
                value={roleData.roleContext || ''}
                onChange={(e) => updateField('roleData', 'roleContext', e.target.value)}
                onClick={(e) => e.stopPropagation()}
                className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all duration-200 text-sm"
                rows={2}
                placeholder={t('roleOverview.contextPlaceholder')}
              />
            </div>
            <GradientButton
              onClick={(e) => {
                e.stopPropagation();
                handleFetchRoleInfo();
              }}
              disabled={isLoading}
              icon={Sparkles}
              loadingText={t('companyProfile.loading')}
              isLoading={isLoading}
              className="!bg-gradient-to-r !from-blue-600 !to-blue-500 hover:!from-blue-700 hover:!to-blue-600 !px-3"
            >
              {t('companyProfile.fetchButton')}
            </GradientButton>
          </div>
        }
      >
        <div className="flex items-center gap-2">
          <Briefcase className="h-5 w-5" />
          <div>
            <CardTitle>{roleData.jobTitle || t('roleOverview.title')}</CardTitle>
            <CardSubHeader>
              {hasAllRequiredFields(roleData, requiredFields) ? (
                <span className="flex items-center min-w-0">
                  <span className="text-green-600 truncate">
                    {roleData.department} • {roleData.roleContext?.substring(0, 50)}...
                  </span>
                </span>
              ) : (
                t('roleOverview.defineDetails')
              )}
            </CardSubHeader>
          </div>
        </div>
      </CardHeader>

      {isExpanded && (
        <CardContent>
          <div className="space-y-4">
            <div className="grid grid-cols-2 gap-4">
              {/* Job Title */}
              <div>
                <label className="block text-sm font-medium mb-1 text-gray-700">{t('roleOverview.fields.jobTitle.label')}</label>
                <input
                  type="text"
                  value={roleData.jobTitle || ''}
                  onChange={(e) => updateField('roleData', 'jobTitle', e.target.value)}
                  className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-sm"
                  placeholder={t('roleOverview.fields.jobTitle.placeholder')}
                />
              </div>

              {/* Department */}
              <div>
                <label className="block text-sm font-medium mb-1 text-gray-700">{t('roleOverview.fields.department.label')}</label>
                <input
                  type="text"
                  value={roleData.department || ''}
                  onChange={(e) => updateField('roleData', 'department', e.target.value)}
                  className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-sm"
                  placeholder={t('roleOverview.fields.department.placeholder')}
                />
              </div>

              {/* Reports To */}
              <div>
                <label className="block text-sm font-medium mb-1 text-gray-700">{t('roleOverview.fields.reportsTo.label')}</label>
                <input
                  type="text"
                  value={roleData.reportsTo || ''}
                  onChange={(e) => updateField('roleData', 'reportsTo', e.target.value)}
                  className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-sm"
                  placeholder={t('roleOverview.fields.reportsTo.placeholder')}
                />
              </div>

              {/* Team Size */}
              <div>
                <label className="block text-sm font-medium mb-1 text-gray-700">{t('roleOverview.fields.teamSize.label')}</label>
                <input
                  type="text"
                  value={roleData.teamSize || ''}
                  onChange={(e) => updateField('roleData', 'teamSize', e.target.value)}
                  className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-sm"
                  placeholder={t('roleOverview.fields.teamSize.placeholder')}
                />
              </div>

              {/* Work Model */}
              <div>
                <label className="block text-sm font-medium mb-1 text-gray-700">{t('roleOverview.fields.workModel.label')}</label>
                <select
                  value={roleData.workModel || ''}
                  onChange={(e) => updateField('roleData', 'workModel', e.target.value)}
                  className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-sm"
                >
                  <option value="">{t('roleOverview.fields.workModel.placeholder')}</option>
                  <option value="Remote">{t('roleOverview.fields.workModel.options.remote')}</option>
                  <option value="Hybrid">{t('roleOverview.fields.workModel.options.hybrid')}</option>
                  <option value="On-site">{t('roleOverview.fields.workModel.options.onsite')}</option>
                </select>
              </div>

              {/* Employment Type */}
              <div>
                <label className="block text-sm font-medium mb-1 text-gray-700">{t('roleOverview.fields.employmentType.label')}</label>
                <select
                  value={roleData.employmentType || ''}
                  onChange={(e) => updateField('roleData', 'employmentType', e.target.value)}
                  className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-sm"
                >
                  <option value="">{t('roleOverview.fields.employmentType.placeholder')}</option>
                  <option value="Full-time">{t('roleOverview.fields.employmentType.options.fullTime')}</option>
                  <option value="Part-time">{t('roleOverview.fields.employmentType.options.partTime')}</option>
                  <option value="Contract">{t('roleOverview.fields.employmentType.options.contract')}</option>
                  <option value="Temporary">{t('roleOverview.fields.employmentType.options.temporary')}</option>
                </select>
              </div>

              {/* Role Context */}
              <div className="col-span-2">
                <label className="block text-sm font-medium mb-1 text-gray-700">{t('roleOverview.fields.roleContext.label')}</label>
                <textarea
                  value={roleData.roleContext || ''}
                  onChange={(e) => updateField('roleData', 'roleContext', e.target.value)}
                  className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-sm"
                  rows={3}
                  placeholder={t('roleOverview.fields.roleContext.placeholder')}
                />
              </div>

              {/* Language with search */}
              <div>
                <label className="block text-sm font-medium mb-1 text-gray-700">{t('roleOverview.fields.language.label')}</label>
                <div className="relative language-dropdown">
                  <input
                    type="text"
                    value={languageSearch}
                    onChange={(e) => setLanguageSearch(e.target.value)}
                    onFocus={() => setIsLanguageDropdownOpen(true)}
                    placeholder={t('roleOverview.fields.language.placeholder')}
                    className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-sm"
                  />
                  {isLanguageDropdownOpen && (
                    <div className="absolute z-10 w-full mt-1 bg-white border rounded-md shadow-lg max-h-60 overflow-auto">
                      {filteredLanguages.map(lang => (
                        <button
                          key={lang.code}
                          onClick={(e) => {
                            e.stopPropagation();
                            updateField('roleData', 'language', lang.code);
                            updateField('roleData', 'currency', getCurrencyForLanguage(lang.code));
                            setLanguageSearch(lang.name);
                            setIsLanguageDropdownOpen(false);
                          }}
                          className="w-full px-4 py-2 text-left text-sm hover:bg-gray-100 focus:bg-gray-100 focus:outline-none"
                        >
                          <span className="font-medium">{lang.name}</span>
                          <span className="text-gray-500 ml-2">({lang.code})</span>
                        </button>
                      ))}
                      {filteredLanguages.length === 0 && (
                        <div className="px-4 py-2 text-sm text-gray-500">
                          {t('roleOverview.fields.language.noResults')}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>

              {/* Salary with currency */}
              <div>
                <label className="block text-sm font-medium mb-1 text-gray-700">{t('roleOverview.fields.salary.label')}</label>
                <div className="flex gap-2">
                  <div className="relative flex-1">
                    <input
                      type="text"
                      value={roleData.salary || ''}
                      onChange={(e) => {
                        const formatted = formatSalaryInput(e.target.value, roleData.currency || 'EUR');
                        updateField('roleData', 'salary', formatted);
                      }}
                      className="w-full p-2 pr-20 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-sm"
                      placeholder={t('roleOverview.fields.salary.placeholder')}
                    />
                    <select
                      value={roleData.currency || 'EUR'}
                      onChange={(e) => updateField('roleData', 'currency', e.target.value)}
                      className="absolute right-0 top-0 h-full px-2 border-l text-sm bg-gray-50 rounded-r-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    >
                      {/* Popular currencies first */}
                      {POPULAR_CURRENCIES.map(code => {
                        const curr = CURRENCIES.find(c => c.code === code);
                        return (
                          <option key={code} value={code}>
                            {code} ({curr?.symbol})
                          </option>
                        );
                      })}
                      
                      <option disabled>──────────</option>
                      
                      {/* All other currencies */}
                      {CURRENCIES
                        .filter(curr => !POPULAR_CURRENCIES.includes(curr.code))
                        .map(curr => (
                          <option key={curr.code} value={curr.code}>
                            {curr.code} ({curr.symbol})
                          </option>
                        ))
                      }
                    </select>
                  </div>
                </div>
              </div>
            </div>

            {/* Toggle fields section */}
            <div className="border-t pt-4">
              {Object.entries(roleData).map(([key, value]) => {
                // Skip fields that are part of the hero section
                if (['jobTitle', 'department', 'reportsTo', 'teamSize', 'workModel', 
                     'employmentType', 'roleContext', 'language', 'salary', 'currency'].includes(key)) {
                  return null;
                }

                // If the value is null, show as a clickable "add" button
                if (value === null) {
                  return (
                    <div 
                      key={key} 
                      className="inline-flex items-center gap-2 px-3 py-1.5 text-sm text-gray-600 
                               border-2 border-dashed border-gray-200 rounded-md cursor-pointer 
                               hover:bg-gray-50 transition-colors mr-2 mb-2"
                      onClick={() => toggleField(key)}
                    >
                      <Plus className="h-4 w-4" />
                      <span>{t('roleOverview.addField', { field: getFieldTranslation(key) })}</span>
                    </div>
                  );
                }

                // Show regular field with remove option
                return (
                  <div key={key} className="mb-4">
                    <div className="flex items-center justify-between mb-1">
                      <label className="block text-sm font-medium text-gray-700">
                        {getFieldTranslation(key)}
                      </label>
                      <button 
                        className="text-gray-400 hover:text-red-500 transition-colors"
                        onClick={() => toggleField(key)}
                      >
                        <X className="h-4 w-4" />
                      </button>
                    </div>
                    <input
                      type="text"
                      value={value}
                      onChange={(e) => updateField('roleData', key, e.target.value)}
                      className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 
                               focus:border-blue-500 text-sm"
                      placeholder={key === 'additionalLanguages' ? t('roleOverview.additionalLanguagesPlaceholder') : t(`roleOverview.fields.${key.toLowerCase()}.placeholder`)}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </CardContent>
      )}
    </Card>
  );
};

export default RoleOverview;
