import React, { useState, useEffect } from 'react';
import { 
  BarChart2, 
  Sparkles, 
  Trash2, 
  PlusCircle,
  ArrowRight,
  ChevronUp,
  ChevronDown
} from 'lucide-react';
import { 
  Card, 
  CardHeader, 
  CardTitle, 
  CardContent, 
  CardSubHeader,
  GradientButton 
} from './CardComponents';
import { fetchQuickAddsOutcomes } from '../utils/fetchQuickAddsOutcomes';
import { useFormContext } from '../contexts/FormContext';
import { showToast, TOAST_TYPES } from './common/ToastManager';
import QuickAddPanel from './common/QuickAddPanel';
import { useTranslation } from 'react-i18next';

const Outcomes = () => {
  const { formData, updateOutcomes } = useFormContext();
  const [outcomes, setOutcomes] = useState(Array.isArray(formData.outcomes) ? formData.outcomes : []);
  const [quickAddOutcomes, setQuickAddOutcomes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isQuickAddCollapsed, setIsQuickAddCollapsed] = useState(false);
  const { t, i18n } = useTranslation('common');

  // Wait for translations to be initialized
  const [translationsReady, setTranslationsReady] = useState(false);

  useEffect(() => {
    const checkTranslations = () => {
      if (i18n.isInitialized && i18n.hasLoadedNamespace('common')) {
        setTranslationsReady(true);
      }
    };

    checkTranslations();

    i18n.on('initialized', checkTranslations);
    i18n.on('loaded', checkTranslations);

    return () => {
      i18n.off('initialized', checkTranslations);
      i18n.off('loaded', checkTranslations);
    };
  }, [i18n]);

  // Initialize outcomes from formData only once on mount
  useEffect(() => {
    if (formData.outcomes) {
      setOutcomes(formData.outcomes);
    }
  }, []); // Empty dependency array means it only runs once on mount

  // Update form context when outcomes change
  useEffect(() => {
    const currentOutcomes = outcomes || [];
    // Only update if there's an actual change
    const hasChanged = JSON.stringify(currentOutcomes) !== JSON.stringify(formData.outcomes || []);
    if (hasChanged) {
      updateOutcomes(currentOutcomes);
    }
  }, [outcomes]);

  // Add timeframe options
  const timeframeOptions = [
    { value: 'first_week', label: t('outcomes.fields.timeframe.options.first_week') },
    { value: 'first_month', label: t('outcomes.fields.timeframe.options.first_month') },
    { value: 'three_months', label: t('outcomes.fields.timeframe.options.three_months') },
    { value: 'six_months', label: t('outcomes.fields.timeframe.options.six_months') },
    { value: 'yearly', label: t('outcomes.fields.timeframe.options.yearly') }
  ];

  // Add helper function for timeframe labels
  const getTimeframeLabel = (value) => {
    const option = timeframeOptions.find(opt => opt.value === value);
    return option ? option.label : value;
  };

  // Add function to generate more quick adds
  const handleGenerateMoreQuickAdds = async () => {
    setIsLoading(true);
    try {
      showToast(t('outcomes.toasts.moreSuggestions.generating'), TOAST_TYPES.INFO);
      const moreSuggestions = await fetchQuickAddsOutcomes(
        formData.companyData, 
        formData.roleData, 
        formData.timelines,
        [...outcomes, ...quickAddOutcomes]
      );
      
      if (moreSuggestions?.length > 0) {
        setQuickAddOutcomes(moreSuggestions);
        showToast(t('outcomes.toasts.moreSuggestions.success'), TOAST_TYPES.SUCCESS);
      } else {
        showToast(t('outcomes.toasts.moreSuggestions.warning'), TOAST_TYPES.WARNING);
      }
    } catch (error) {
      console.error('Error generating more suggestions:', error);
      showToast(t('outcomes.toasts.moreSuggestions.error'), TOAST_TYPES.ERROR);
    } finally {
      setIsLoading(false);
    }
  };

  const handleGenerateQuickAdds = async (e) => {
    e?.stopPropagation();
    setIsLoading(true);
    try {
      showToast(t('outcomes.toasts.generating'), TOAST_TYPES.INFO);
      
      const mainOutcomes = await fetchQuickAddsOutcomes(
        formData.companyData, 
        formData.roleData, 
        formData.timelines,
        outcomes
      );
      
      if (mainOutcomes?.length > 0) {
        const newOutcomes = mainOutcomes.map(item => ({
          id: `outcome_${Date.now()}_${Math.random()}`,
          text: item.outcome,
          timeframe: item.timeframe
        }));
        
        setOutcomes(prev => [...prev, ...newOutcomes]);
        setIsExpanded(true);
        showToast(t('outcomes.toasts.success'), TOAST_TYPES.SUCCESS);
      } else {
        console.warn('No outcomes generated:', mainOutcomes);
        showToast(t('outcomes.toasts.warning'), TOAST_TYPES.WARNING);
      }
    } catch (error) {
      console.error('Error generating outcomes:', error);
      showToast(t('outcomes.toasts.error'), TOAST_TYPES.ERROR);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddQuickAdd = (quickAddItem) => {
    setOutcomes(prev => [
      ...prev,
      {
        id: `outcome_${Date.now()}`,
        text: quickAddItem.outcome,
        timeframe: quickAddItem.timeframe
      }
    ]);
  };

  const handleUpdateOutcome = (id, key, value) => {
    setOutcomes(prev => 
      prev.map(item => item.id === id ? { ...item, [key]: value } : item)
    );
  };

  const handleDeleteOutcome = (id) => {
    setOutcomes(prev => prev.filter(item => item.id !== id));
  };

  const handleAddOutcome = () => {
    setOutcomes(prev => [
      ...prev,
      {
        id: `outcome_${Date.now()}`,
        text: '',
        timeframe: ''
      }
    ]);
  };

  const handleReorderOutcome = (currentId, direction) => {
    const currentIndex = outcomes.findIndex(item => item.id === currentId);
    const newIndex = currentIndex + direction;
    
    if (newIndex >= 0 && newIndex < outcomes.length) {
      const newList = [...outcomes];
      const [movedItem] = newList.splice(currentIndex, 1);
      newList.splice(newIndex, 0, movedItem);
      setOutcomes(newList);
    }
  };

  // If translations aren't ready yet, show a loading state or return null
  if (!translationsReady) {
    return (
      <Card className="max-w-5xl mx-auto">
        <CardHeader>
          <div className="flex items-center gap-2">
            <BarChart2 className="h-5 w-5" />
            <div>
              <CardTitle>Loading...</CardTitle>
            </div>
          </div>
        </CardHeader>
      </Card>
    );
  }

  return (
    <Card className="max-w-5xl mx-auto">
      <CardHeader 
        data={{ outcomes }}
        requiredFields={['outcomes']}
        isExpanded={isExpanded}
        onClick={() => setIsExpanded(!isExpanded)}
        inputSection={
          <div className="flex items-center gap-2">
            <GradientButton
              onClick={(e) => {
                e.stopPropagation();
                handleGenerateQuickAdds();
              }}
              disabled={isLoading}
              icon={Sparkles}
              loadingText={t('outcomes.buttons.generating')}
              isLoading={isLoading}
              className="!bg-gradient-to-r !from-blue-600 !to-blue-500 hover:!from-blue-700 hover:!to-blue-600 !px-3"
            >
              {t('outcomes.buttons.generate')}
            </GradientButton>
          </div>
        }
      >
        <div className="flex items-center gap-2">
          <BarChart2 className="h-5 w-5" />
          <div>
            <CardTitle>{t('outcomes.title')}</CardTitle>
            <CardSubHeader 
              count={outcomes.length || undefined}
              emptyText={t('outcomes.emptyText')}
            >
              {t('outcomes.subtitle')}
            </CardSubHeader>
          </div>
        </div>
      </CardHeader>

      {isExpanded && (
        <CardContent className="space-y-6">
          <div className={`grid ${isQuickAddCollapsed ? 'grid-cols-1' : 'grid-cols-3'} gap-6`}>
            {/* Left Column: Outcomes List */}
            <div className={isQuickAddCollapsed ? 'col-span-1' : 'col-span-2'}>
              {outcomes.map((item) => (
                <div key={item.id} className="flex items-center gap-3">
                  <input
                    type="text"
                    value={item.text}
                    onChange={(e) => handleUpdateOutcome(item.id, 'text', e.target.value)}
                    className="flex-1 p-2 border border-gray-300 rounded-md text-xs"
                    placeholder={t('outcomes.fields.outcome.placeholder')}
                  />
                  <select
                    value={item.timeframe}
                    onChange={(e) => handleUpdateOutcome(item.id, 'timeframe', e.target.value)}
                    className="p-2 border border-gray-300 rounded-md text-xs w-40"
                  >
                    <option value="">{t('outcomes.fields.timeframe.label')}</option>
                    {timeframeOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                  <div className="flex items-center gap-1">
                    <div className="flex flex-col">
                      <button
                        onClick={() => handleReorderOutcome(item.id, -1)}
                        disabled={item.id === outcomes[0].id}
                        className="p-0.5 rounded hover:bg-gray-100 disabled:opacity-50 disabled:hover:bg-transparent"
                      >
                        <ChevronUp className="h-3.5 w-3.5 text-gray-600" />
                      </button>
                      <button
                        onClick={() => handleReorderOutcome(item.id, 1)}
                        disabled={item.id === outcomes[outcomes.length - 1].id}
                        className="p-0.5 rounded hover:bg-gray-100 disabled:opacity-50 disabled:hover:bg-transparent"
                      >
                        <ChevronDown className="h-3.5 w-3.5 text-gray-600" />
                      </button>
                    </div>
                    <button
                      onClick={() => handleDeleteOutcome(item.id)}
                      className="p-1 rounded hover:bg-red-100"
                    >
                      <Trash2 className="h-4 w-4 text-red-600" />
                    </button>
                  </div>
                </div>
              ))}

              <button
                onClick={handleAddOutcome}
                className="flex items-center gap-1.5 text-blue-600 hover:text-blue-800 mt-4 text-sm"
              >
                <PlusCircle className="h-4 w-4" />
                {t('outcomes.buttons.add')}
              </button>
            </div>

            {/* Replace the existing Quick Add section with QuickAddPanel */}
            <QuickAddPanel
              isCollapsed={isQuickAddCollapsed}
              onCollapse={setIsQuickAddCollapsed}
              isLoading={isLoading}
              items={quickAddOutcomes}
              onItemClick={handleAddQuickAdd}
              onReload={handleGenerateMoreQuickAdds}
              translationPrefix="quickAdd"
              renderItem={(example, index) => (
                <button
                  key={index}
                  onClick={() => handleAddQuickAdd(example)}
                  className="w-full flex items-center gap-2 p-2 bg-white rounded border border-gray-200 text-xs hover:border-blue-500 hover:bg-blue-50 transition-colors"
                >
                  <ArrowRight className="h-3 w-3 text-gray-400 flex-shrink-0" />
                  <div className="text-left flex-1">
                    <span className="text-gray-600">{example.outcome}</span>
                    <span className="text-gray-400 ml-2">•</span>
                    <span className="text-gray-400 ml-2">{t(`outcomes.fields.timeframe.options.${example.timeframe}`)}</span>
                  </div>
                </button>
              )}
            />
          </div>
        </CardContent>
      )}
    </Card>
  );
};

export default Outcomes;
