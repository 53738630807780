import { Document, Paragraph, TextRun, HeadingLevel, Packer } from 'docx';
import { FACTOR_CATEGORIES, factors } from '../interviewFactorsList';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

export const generateEvaluationSchemeDOCX = async (questions, formData) => {
  try {
    // Get translations
    const t = i18next.getFixedT(formData.roleData?.language || 'en', 'common');

    // Create sanitized filename
    const jobTitle = (formData?.roleData?.jobTitle || 'role').replace(/[^a-z0-9]/gi, '-').toLowerCase();
    const companyName = (formData?.companyData?.name || 'company').replace(/[^a-z0-9]/gi, '-').toLowerCase();
    const fileName = `candidate-evaluation-${companyName}-${jobTitle}.docx`;

    // Create document
    const doc = new Document({
      sections: [{
        properties: {},
        children: [
          // Header using translations
          new Paragraph({
            text: t('evaluation.title'),
            heading: HeadingLevel.HEADING_1,
            spacing: { after: 200 }
          }),

          // Position info using translations
          new Paragraph({
            children: [
              new TextRun({
                text: `${t('evaluation.position')}: ${formData?.roleData?.jobTitle || 'Role'}`,
                size: 24
              })
            ],
            spacing: { after: 200 }
          }),

          // Input fields using translations
          new Paragraph({
            children: [
              new TextRun({ 
                text: t('evaluation.candidateName'),
                bold: true 
              }),
              new TextRun({ text: '________________________________' }),
            ],
            spacing: { after: 200 }
          }),
          new Paragraph({
            children: [
              new TextRun({ text: 'Interview Date: ', bold: true }),
              new TextRun({ text: '________________________________' }),
            ],
            spacing: { after: 200 }
          }),
          new Paragraph({
            children: [
              new TextRun({ text: 'Interviewer: ', bold: true }),
              new TextRun({ text: '________________________________' }),
            ],
            spacing: { after: 400 }
          }),

          // Overall Evaluation (moved to front)
          new Paragraph({
            children: [
              new TextRun({
                text: 'Overall Evaluation',
                bold: true,
                size: 28
              })
            ],
            spacing: { before: 400, after: 200 }
          }),
          new Paragraph({
            text: 'Please provide your overall assessment of the candidate based on all evaluation criteria.',
            spacing: { after: 200 }
          }),
          new Paragraph({
            children: [
              new TextRun({ text: 'Final Score (1-5): ', bold: true }),
              new TextRun({ text: '______' }),
            ],
            spacing: { after: 200 }
          }),
          new Paragraph({
            children: [
              new TextRun({ text: 'Recommendation:', bold: true }),
              new TextRun({ text: '\n□ Strongly Recommend\n□ Recommend\n□ Do Not Recommend' }),
            ],
            spacing: { after: 200 }
          }),
          new Paragraph({
            children: [
              new TextRun({ text: 'Additional Comments:', bold: true }),
              new TextRun({ text: '\n_____________________________________\n_____________________________________' }),
            ],
            spacing: { after: 400 }
          }),

          // Page break after overall evaluation
          new Paragraph({
            text: '',
            pageBreakBefore: true
          }),

          // Category Evaluation Headers
          ...Object.entries(FACTOR_CATEGORIES).flatMap(([categoryId, category]) => [
            new Paragraph({
              children: [
                new TextRun({
                  text: `${category.label} Evaluation`,
                  bold: true,
                  size: 28
                })
              ],
              spacing: { before: 400, after: 200 }
            }),
            new Paragraph({
              text: category.description,
              spacing: { after: 200 }
            }),
            new Paragraph({
              children: [
                new TextRun({ text: 'Score (1-5): ', bold: true }),
                new TextRun({ text: '______' }),
              ],
              spacing: { after: 200 }
            }),
            new Paragraph({
              children: [
                new TextRun({ text: 'Comments: ', bold: true }),
                new TextRun({ text: '_____________________________________' }),
              ],
              spacing: { after: 400 }
            }),
          ]),

          // Question Evaluations
          ...questions.flatMap((question, index) => {
            const factor = factors.find(f => f.id === question.type);
            const category = factor ? FACTOR_CATEGORIES[factor.category] : null;
            const categoryLabel = category?.label || factor?.label || 'General';

            return [
              new Paragraph({
                children: [
                  new TextRun({
                    text: `Question ${index + 1}: ${categoryLabel}`,
                    bold: true,
                    size: 24
                  })
                ],
                spacing: { before: 400, after: 200 }
              }),
              new Paragraph({
                text: question.text,
                spacing: { after: 200 }
              }),
              new Paragraph({
                children: [
                  new TextRun({ text: 'Evaluation Criteria:', italics: true })
                ],
                spacing: { before: 200, after: 100 }
              }),
              ...(Array.isArray(question.description) 
                ? question.description.map(point => new Paragraph({
                    text: `• ${point}`,
                    indent: { left: 400 },
                    spacing: { after: 100 }
                  }))
                : [new Paragraph({
                    text: `• ${question.description}`,
                    indent: { left: 400 },
                    spacing: { after: 100 }
                  })]),
              new Paragraph({
                children: [
                  new TextRun({ text: 'Score (1-5): ', bold: true }),
                  new TextRun({ text: '______' }),
                ],
                spacing: { after: 200 }
              }),
              new Paragraph({
                children: [
                  new TextRun({ text: 'Notes: ', bold: true }),
                  new TextRun({ text: '_____________________________________' }),
                ],
                spacing: { after: 400 }
              }),
            ];
          }),
        ]
      }]
    });

    // Generate and download the document
    const blob = await Packer.toBlob(doc);
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Error generating evaluation DOCX:', error);
    throw error;
  }
}; 