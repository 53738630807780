import jsPDF from 'jspdf';
import { FACTOR_CATEGORIES, factors } from '../interviewFactorsList';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

export const generateEvaluationPDF = (questions, formData) => {
  // Get translations
  const t = i18next.getFixedT(formData.roleData?.language || 'en', 'common');

  const doc = new jsPDF();
  let yPos = 20;
  const lineHeight = 7;
  const margin = 20;
  const pageWidth = 210;
  const contentWidth = pageWidth - (margin * 2);

  // Helper function for word wrapping
  const splitTextToSize = (text, width) => {
    return doc.splitTextToSize(text, width);
  };

  // First Page: Overall Evaluation
  // Header
  doc.setFontSize(16);
  doc.text(t('evaluation.title'), margin, yPos);
  yPos += lineHeight * 2;

  // Position and candidate info
  doc.setFontSize(12);
  doc.text(`${t('evaluation.company')}: ${formData?.companyData?.name || 'Company'}`, margin, yPos);
  yPos += lineHeight;
  doc.text(`${t('evaluation.department')}: ${formData?.roleData?.department || 'Department'}`, margin, yPos);
  yPos += lineHeight * 2;

  doc.text(`${t('evaluation.candidateName')}: _________________________`, margin, yPos);
  yPos += lineHeight;
  doc.text(`${t('evaluation.interviewDate')}: _________________________`, margin, yPos);
  yPos += lineHeight;
  doc.text(`${t('evaluation.interviewer')}: _________________________`, margin, yPos);
  yPos += lineHeight * 3;

  // Overall Evaluation Section
  doc.setFontSize(14);
  doc.setFont(undefined, 'bold');
  doc.text(t('evaluation.overallEvaluation'), margin, yPos);
  yPos += lineHeight * 1.5;

  doc.setFontSize(10);
  doc.setFont(undefined, 'normal');
  const evaluationText = splitTextToSize(t('evaluation.overallAssessment'), contentWidth);
  doc.text(evaluationText, margin, yPos);
  yPos += lineHeight * evaluationText.length + lineHeight;

  doc.setFontSize(12);
  doc.text(t('evaluation.finalScore'), margin, yPos);
  yPos += lineHeight * 2;

  doc.text(t('evaluation.recommendation'), margin, yPos);
  yPos += lineHeight;
  doc.text(`□ ${t('evaluation.stronglyRecommend')}`, margin + 5, yPos);
  yPos += lineHeight;
  doc.text(`□ ${t('evaluation.recommend')}`, margin + 5, yPos);
  yPos += lineHeight;
  doc.text(`□ ${t('evaluation.doNotRecommend')}`, margin + 5, yPos);
  yPos += lineHeight * 2;

  doc.text(t('evaluation.additionalComments'), margin, yPos);
  yPos += lineHeight;
  doc.text('________________________________', margin, yPos);
  yPos += lineHeight;
  doc.text('________________________________', margin, yPos);

  // Start new page for detailed evaluations
  doc.addPage();
  yPos = 20;

  // Add category evaluations
  Object.entries(FACTOR_CATEGORIES).forEach(([categoryId, category]) => {
    // Check if we need a new page
    if (yPos > 250) {
      doc.addPage();
      yPos = 20;
    }

    doc.setFontSize(14);
    doc.setFont(undefined, 'bold');
    doc.text(`${category.label} Evaluation`, margin, yPos);
    yPos += lineHeight;

    doc.setFontSize(10);
    doc.setFont(undefined, 'normal');
    const description = splitTextToSize(category.description, contentWidth);
    doc.text(description, margin, yPos);
    yPos += lineHeight * description.length + lineHeight;

    doc.text('Score (1-5): _____', margin, yPos);
    yPos += lineHeight;
    doc.text('Comments: ________________________________', margin, yPos);
    yPos += lineHeight * 2;
  });

  // Add questions evaluation
  questions.forEach((question, index) => {
    // Check if we need a new page
    if (yPos > 250) {
      doc.addPage();
      yPos = 20;
    }

    const factor = factors.find(f => f.id === question.type);
    const category = factor ? FACTOR_CATEGORIES[factor.category] : null;
    const categoryLabel = category?.label || factor?.label || 'General';

    doc.setFontSize(12);
    doc.setFont(undefined, 'bold');
    doc.text(`Question ${index + 1}: ${categoryLabel}`, margin, yPos);
    yPos += lineHeight;

    doc.setFont(undefined, 'normal');
    const questionText = splitTextToSize(question.text, contentWidth);
    doc.text(questionText, margin, yPos);
    yPos += lineHeight * questionText.length + lineHeight;

    doc.setFontSize(10);
    doc.text('Evaluation Criteria:', margin, yPos);
    yPos += lineHeight;

    if (Array.isArray(question.description)) {
      question.description.forEach(point => {
        const bulletPoint = splitTextToSize(`• ${point}`, contentWidth - 5);
        doc.text(bulletPoint, margin + 5, yPos);
        yPos += lineHeight * bulletPoint.length;
      });
    } else {
      const description = splitTextToSize(`• ${question.description}`, contentWidth - 5);
      doc.text(description, margin + 5, yPos);
      yPos += lineHeight * description.length;
    }

    doc.text('Score (1-5): _____', margin, yPos);
    yPos += lineHeight;
    doc.text('Notes: ________________________________', margin, yPos);
    yPos += lineHeight * 2;
  });

  // Save the PDF
  const jobTitle = (formData?.roleData?.jobTitle || 'role').replace(/[^a-z0-9]/gi, '-').toLowerCase();
  const companyName = (formData?.companyData?.name || 'company').replace(/[^a-z0-9]/gi, '-').toLowerCase();
  const fileName = `candidate-evaluation-${companyName}-${jobTitle}.pdf`;
  doc.save(fileName);
}; 