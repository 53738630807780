import { fetchFromChatGPT } from './fetchFromChatGPT';
import i18next from 'i18next';

const cleanGPTResponse = (content) => {
  if (typeof content !== 'string') return content;
  
  const cleaned = content
    .replace(/```json\n?/g, '')
    .replace(/```\n?/g, '')
    .trim();

  try {
    const parsed = JSON.parse(cleaned);
    return parsed.map(item => ({
      ...item,
      timeframe: new Date(item.timeframe).toISOString().split('T')[0]
    }));
  } catch (error) {
    console.warn('Failed to parse cleaned content, attempting to extract JSON array');
    const match = cleaned.match(/\[[\s\S]*\]/);
    if (match) {
      return JSON.parse(match[0]);
    }
    throw new Error('No valid JSON array found in response');
  }
};

const getToday = () => {
  // Get current date in user's timezone
  const now = new Date();
  // Reset to midnight in user's timezone
  now.setHours(0, 0, 0, 0);
  // Convert to ISO string and take just the date part
  return now.toISOString().split('T')[0];
};

const generateTimelinePrompt = (roleData, existingSteps) => {
  const today = getToday();
  const targetDate = new Date(roleData.targetDate);
  
  // Sort steps by date and format them with dates for context
  const sortedSteps = existingSteps
    .sort((a, b) => new Date(a.date) - new Date(b.date))
    .map(step => `${step.text} (${step.date})`)
    .join('\n- ');

  const t = i18next.getFixedT(roleData.language || 'en');
  
  return `${t('prompts.timeline.title')}
${t('prompts.timeline.startDate')}: ${roleData.targetDate}

${t('prompts.timeline.existingSteps')}:
- ${sortedSteps}

${t('prompts.timeline.instructions.title')}
- ${t('prompts.timeline.instructions.different')}
- ${t('prompts.timeline.instructions.dateBetween', { today, targetDate: roleData.targetDate })}
- ${t('prompts.timeline.instructions.specificDates')}
- ${t('prompts.timeline.instructions.focus')}
- ${t('prompts.timeline.instructions.spacing')}

${t('prompts.timeline.format.title')}
- ${t('prompts.timeline.format.suggestions')}
- ${t('prompts.timeline.format.timeframe')}
- ${t('prompts.timeline.format.duration')}

${t('prompts.timeline.return')}`;
};

export const fetchQuickAddTimeline = async ({ targetDate, existingSteps, language = 'en', referenceDate = null }) => {
  const roleData = { 
    targetDate, 
    language,
    // If a reference date is provided, use it as the base for calculations
    today: referenceDate || getToday()
  };
  const prompt = generateTimelinePrompt(roleData, existingSteps);

  const t = i18next.getFixedT(language || 'en');

  const payload = {
    model: 'gpt-3.5-turbo',
    messages: [
      { role: 'system', content: t('prompts.timeline.systemRole') },
      { role: 'user', content: prompt },
    ],
    max_tokens: 300,
    temperature: 0.7,
  };

  try {
    const data = await fetchFromChatGPT(payload, {
      language: language || 'en',
      languageName: language === 'da' ? 'Danish' : 'English'
    });
    const content = data.choices[0].message.content;
    const parsed = cleanGPTResponse(content);
    
    return Array.isArray(parsed) 
      ? parsed
          .filter((step) => step.Suggestions && step.timeframe)
          .slice(0, 5)
      : [];
  } catch (error) {
    console.error('Error fetching quick add suggestions:', error);
    return [];
  }
};
