import React, { useState } from 'react';
import { fetchCompanyProfile } from '../utils/fetchCompanyProfile';
import { Building, Globe, Sparkles, ChevronDown, ChevronUp } from 'lucide-react';
import { 
  Card, 
  CardHeader, 
  CardTitle, 
  CardContent,
  GradientButton,
  CardSubHeader,
  hasAllRequiredFields
} from '../components/CardComponents';
import { useFormContext } from '../contexts/FormContext';
import { showToast, TOAST_TYPES } from './common/ToastManager';
import { useTranslation } from 'react-i18next';

const CompanyProfile = () => {
  const { formData, updateField } = useFormContext();
  const companyData = formData.companyData || {};
  const [isLoading, setIsLoading] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const { t, i18n } = useTranslation();

  const requiredFields = ['name', 'industry', 'description', 'culture'];

  const handleFetchCompanyInfo = async () => {
    if (!companyData.companyUrl) return;
    
    setIsLoading(true);
    try {
      showToast(t('companyProfile.toasts.fetching'), TOAST_TYPES.INFO);
      
      const data = await fetchCompanyProfile(companyData.companyUrl, i18n.language);
      if (data) {
        showToast(t('companyProfile.toasts.fetchSuccess'), TOAST_TYPES.SUCCESS);
        
        Object.entries(data).forEach(([key, value], index) => {
          setTimeout(() => {
            updateField('companyData', key, value || '');
            showToast(t('companyProfile.toasts.fieldUpdated', { field: key.replace(/([A-Z])/g, ' $1').toLowerCase() }), TOAST_TYPES.INFO);
          }, index * 100);
        });

        setTimeout(() => {
          showToast(t('companyProfile.toasts.allUpdated'), TOAST_TYPES.SUCCESS);
          setIsExpanded(true);
        }, Object.keys(data).length * 100 + 100);

      } else {
        showToast(t('companyProfile.toasts.noData'), TOAST_TYPES.WARNING);
      }
    } catch (error) {
      console.error('Error fetching company profile:', error);
      showToast(t('companyProfile.toasts.fetchError'), TOAST_TYPES.ERROR);
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (field, value) => {
    updateField('companyData', field, value);
  };

  return (
    <Card className="max-w-5xl mx-auto">
      <CardHeader 
        data={companyData}
        requiredFields={requiredFields}
        isExpanded={isExpanded}
        onClick={() => setIsExpanded(!isExpanded)}
        inputSection={
          <div className="flex items-center gap-2">
            <div className="flex items-center gap-2 relative w-[250px]">
              <Globe className="absolute left-3 h-5 w-5 text-gray-400" />
              <input
                type="url"
                value={companyData.companyUrl || ''}
                onChange={(e) => handleInputChange('companyUrl', e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && companyData.companyUrl && !isLoading) {
                    e.preventDefault();
                    handleFetchCompanyInfo();
                  }
                }}
                className="w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                placeholder={t('companyProfile.urlPlaceholder')}
                onClick={(e) => e.stopPropagation()}
              />
            </div>
            <GradientButton
              onClick={(e) => {
                e.stopPropagation();
                handleFetchCompanyInfo();
              }}
              disabled={isLoading || !companyData.companyUrl}
              icon={Sparkles}
              loadingText={t('companyProfile.loading')}
              isLoading={isLoading}
              className="!bg-gradient-to-r !from-blue-600 !to-blue-500 hover:!from-blue-700 hover:!to-blue-600 !px-3"
            >
              {t('companyProfile.fetchButton')}
            </GradientButton>
          </div>
        }
      >
        <div className="flex items-center gap-2">
          <Building className="h-5 w-5" />
          <div>
            <CardTitle>{companyData.name || t('companyProfile.title')}</CardTitle>
            <CardSubHeader>
              {hasAllRequiredFields(companyData, requiredFields) ? (
                <span className="flex items-center min-w-0">
                  <span className="text-green-600 truncate">
                    {companyData.industry} • {companyData.description?.substring(0, 50)}...
                  </span>
                </span>
              ) : (
                t('companyProfile.addDetails')
              )}
            </CardSubHeader>
          </div>
        </div>
      </CardHeader>

      {isExpanded && (
        <CardContent className="space-y-6">
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                {t('companyProfile.companyName')}
              </label>
              <input
                type="text"
                value={companyData.name || ''}
                onChange={(e) => handleInputChange('name', e.target.value)}
                className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                {t('companyProfile.industry')}
              </label>
              <input
                type="text"
                value={companyData.industry || ''}
                onChange={(e) => handleInputChange('industry', e.target.value)}
                className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>

            <div className="col-span-2">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                {t('companyProfile.description')}
              </label>
              <textarea
                value={companyData.description || ''}
                onChange={(e) => handleInputChange('description', e.target.value)}
                rows={3}
                className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>

            <div className="col-span-2">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                {t('companyProfile.culture')}
              </label>
              <textarea
                value={companyData.culture || ''}
                onChange={(e) => handleInputChange('culture', e.target.value)}
                rows={3}
                className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
          </div>
        </CardContent>
      )}
    </Card>
  );
};

export default CompanyProfile;
