import React, { useState } from 'react';
import { Sparkles, FileText, Target, Search, ChevronDown, ChevronUp } from 'lucide-react';
import { CardTitle, CardSubHeader } from './CardComponents';
import { useTranslation } from 'react-i18next';

const RoleDesignerHero = () => {
  const [isExpanded, setIsExpanded] = useState(true);
  const { t } = useTranslation();

  return (
    <div className="bg-gray-50">
      <div className="max-w-5xl mx-auto">
        <div 
          onClick={() => setIsExpanded(!isExpanded)}
          className="bg-gradient-to-r from-gray-800 to-gray-900 rounded-lg p-4 cursor-pointer"
        >
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <Sparkles className="h-5 w-5 text-blue-400" />
              <div>
                <CardTitle className="text-white">{t('hero.title')}</CardTitle>
                <CardSubHeader className="text-gray-300">
                  {t('hero.subtitle')}
                </CardSubHeader>
              </div>
            </div>
            {isExpanded ? (
              <ChevronUp className="h-5 w-5 text-gray-400" />
            ) : (
              <ChevronDown className="h-5 w-5 text-gray-400" />
            )}
          </div>
        </div>

        {/* Expandable Content */}
        {isExpanded && (
          <div className="grid grid-cols-3 gap-6 px-6 pt-6 pb-6">
            <div className="bg-white rounded-lg p-7 shadow-md border-t-4 border-blue-500 hover:shadow-lg transition-shadow">
              <div className="flex flex-col items-start space-y-3">
                <div className="p-2.5 bg-gray-50 rounded-lg">
                  <FileText className="h-7 w-7 text-blue-600" />
                </div>
                <h3 className="text-lg font-medium text-gray-900">{t('hero.jobPost.title')}</h3>
                <p className="text-gray-600 leading-relaxed">
                  {t('hero.jobPost.description')}
                </p>
              </div>
            </div>

            <div className="bg-white rounded-lg p-7 shadow-md border-t-4 border-blue-500 hover:shadow-lg transition-shadow">
              <div className="flex flex-col items-start space-y-3">
                <div className="p-2.5 bg-gray-50 rounded-lg">
                  <Target className="h-7 w-7 text-blue-600" />
                </div>
                <h3 className="text-lg font-medium text-gray-900">{t('hero.skillAssessments.title')}</h3>
                <p className="text-gray-600 leading-relaxed">
                  {t('hero.skillAssessments.description')}
                </p>
              </div>
            </div>

            <div className="bg-white rounded-lg p-7 shadow-md border-t-4 border-blue-500 hover:shadow-lg transition-shadow">
              <div className="flex flex-col items-start space-y-3">
                <div className="p-2.5 bg-gray-50 rounded-lg">
                  <Search className="h-7 w-7 text-blue-600" />
                </div>
                <h3 className="text-lg font-medium text-gray-900">{t('hero.interviewQuestions.title')}</h3>
                <p className="text-gray-600 leading-relaxed">
                  {t('hero.interviewQuestions.description')}
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default RoleDesignerHero;