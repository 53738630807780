export async function fetchFromChatGPT(payload, options = {}) {
  const GPT_WORKER_URL = 'https://predicthire-free-fetch.philip-d02.workers.dev';

  try {
    // Ensure we always have language options
    const languageOptions = {
      language: options.language || 'en',
      languageName: options.languageName || 'English'
    };

    console.log('Payload sent to GPT Worker:', {
      endpoint: 'https://api.openai.com/v1/chat/completions',
      payloadSummary: {
        model: payload.model,
        messageCount: payload.messages?.length,
      },
      ...languageOptions
    });

    const response = await fetch(GPT_WORKER_URL, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        endpoint: 'https://api.openai.com/v1/chat/completions',
        payload,
        ...languageOptions
      }),
    });

    if (!response.ok) {
      const errorText = await response.text();
      console.error('GPT Worker Error:', {
        status: response.status,
        text: errorText,
        language: languageOptions.language
      });
      throw new Error(`GPT Worker Error: ${errorText}`);
    }

    const data = await response.json();
    console.log('GPT Worker Response:', {
      status: 'success',
      language: languageOptions.language,
      hasContent: !!data.choices?.[0]?.message?.content
    });

    return data;
  } catch (error) {
    console.error('Error fetching from GPT Worker:', error);
    throw error;
  }
}
