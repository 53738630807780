import { fetchFromChatGPT } from './fetchFromChatGPT';
import { formatDataForAPI } from './dataStructure';

/**
 * Fetch Quick Add Responsibilities
 * Fetches responsibilities suggestions from GPT based on the provided form data.
 *
 * @param {object} formData - The aggregated form data from FormContext.
 * @param {boolean} trigger - Whether to trigger the GPT call
 * @param {Array} existingResponsibilities - Array of existing responsibilities to avoid duplicates
 * @returns {Array} Array of responsibilities, or an empty array on failure.
 */
export const fetchQuickAddsResponsibilities = async (formData = {}, trigger = false, existingResponsibilities = []) => {
  console.log('[fetchQuickAddsResponsibilities] Received formData:', formData, 'Trigger:', trigger);

  if (!trigger) {
    return [];
  }

  try {
    const inputData = formatDataForAPI(formData);
    console.log('[fetchQuickAddsResponsibilities] Formatted Input Data:', inputData);

    // Extract existing responsibility texts for comparison
    const existingTexts = existingResponsibilities
      .map(r => typeof r === 'string' ? r : r.text)
      .filter(Boolean)
      .map(text => text.toLowerCase().trim());

    const prompt = `Generate NEW and UNIQUE key responsibilities for this role.
Return EXACTLY 4 responsibilities that are:
- DIFFERENT from any existing ones
- SPECIFIC and ACTIONABLE
- RELEVANT to the role and company
- Written in ${formData.roleData?.language === 'da' ? 'Danish' : 'English'}

${existingTexts.length > 0 ? `
EXISTING RESPONSIBILITIES (DO NOT REPEAT THESE):
${existingTexts.map(text => `- ${text}`).join('\n')}
` : ''}

Role and Company Details:
${JSON.stringify(inputData, null, 2)}

Return ONLY a JSON array of strings, like this: ["responsibility 1", "responsibility 2", "responsibility 3", "responsibility 4"].
No other text, formatting, or explanation allowed.`;

    const gptPayload = {
      model: 'gpt-3.5-turbo',
      messages: [
        {
          role: 'system',
          content: `You are an expert at generating key responsibilities for job roles. Generate all content in ${formData.roleData?.language === 'da' ? 'Danish' : 'English'}. Focus on creating UNIQUE and DIVERSE responsibilities each time.`,
        },
        {
          role: 'user',
          content: prompt,
        },
      ],
      temperature: 0.8, // Increased for more variety
      max_tokens: 500,
    };

    const response = await fetchFromChatGPT(gptPayload, {
      language: formData.roleData?.language || 'en',
      languageName: formData.roleData?.language === 'da' ? 'Danish' : 'English'
    });
    const content = response.choices?.[0]?.message?.content || '';
    let parsed = [];

    try {
      parsed = JSON.parse(content);
    } catch (error) {
      console.warn('[fetchQuickAddsResponsibilities] Failed to parse GPT response. Attempting to extract JSON.', { content });
      const jsonMatch = content.match(/\[.*\]/s);
      if (jsonMatch) {
        parsed = JSON.parse(jsonMatch[0]);
      }
    }

    return Array.isArray(parsed) ? parsed.slice(0, 4) : [];
  } catch (error) {
    console.error('[fetchQuickAddsResponsibilities] Error:', error);
    return [];
  }
};
