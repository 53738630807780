import React from 'react';
import { AlertCircle, BrainCircuit } from 'lucide-react';
import { QUESTION_TYPES } from '../utils/questionTypeStyles';
import { useTranslation } from 'react-i18next';

const QuestionWarnings = ({
  wordCount,
  isQuestionTooLong,
  isQuestionComplex,
  questionId,
  questionType,
  activeTooltip,
  setActiveTooltip,
}) => {
  const { t } = useTranslation();
  
  const getTypeInfo = (type) => {
    const typeInfo = {
      warmup: {
        type: t('interviewQuestions.types.warmup'),
        max: 15,
        tip: t('interviewQuestions.tips.warmup')
      },
      background: {
        type: t('interviewQuestions.types.background'),
        max: 20,
        tip: t('interviewQuestions.tips.background')
      },
      technical: {
        type: t('interviewQuestions.types.technical'),
        max: 20,
        tip: t('interviewQuestions.tips.technical')
      },
      behavioral: {
        type: t('interviewQuestions.types.behavioral'),
        max: 30,
        tip: t('interviewQuestions.tips.behavioral')
      },
      situational: {
        type: t('interviewQuestions.types.situational'),
        max: 25,
        tip: t('interviewQuestions.tips.situational')
      },
      cultural: {
        type: t('interviewQuestions.types.cultural'),
        max: 20,
        tip: t('interviewQuestions.tips.cultural')
      },
      closing: {
        type: t('interviewQuestions.types.closing'),
        max: 15,
        tip: t('interviewQuestions.tips.closing')
      }
    };
    return typeInfo[type] || typeInfo.behavioral;
  };

  const typeInfo = getTypeInfo(questionType);
  
  return (
    <div className="flex items-center gap-2">
      <div className="relative">
        <div 
          onClick={() => setActiveTooltip(activeTooltip === `wordcount-${questionId}` ? null : `wordcount-${questionId}`)}
          className={`text-xs px-1.5 py-0.5 rounded ${
            isQuestionTooLong
              ? 'bg-orange-50 text-orange-600 cursor-help hover:bg-orange-100'
              : wordCount > 0
                ? 'bg-green-50 text-green-600'
                : 'bg-gray-50 text-gray-600'
          }`}
        >
          {wordCount} {t('interviewQuestions.warnings.words')}
        </div>
        {isQuestionTooLong && activeTooltip === `wordcount-${questionId}` && (
          <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-3 py-2 bg-gray-900 text-white text-xs rounded whitespace-nowrap">
            <div>{t('interviewQuestions.warnings.tooLong', { count: wordCount })}</div>
            <div className="mt-1">{t('interviewQuestions.warnings.shouldBeLess', { type: typeInfo.type, max: typeInfo.max })}</div>
            <div className="mt-1 text-gray-300">{typeInfo.tip}</div>
          </div>
        )}
      </div>
      
      {isQuestionComplex && (
        <div className="relative">
          <div 
            onClick={() => setActiveTooltip(activeTooltip === `complex-${questionId}` ? null : `complex-${questionId}`)}
            className="text-xs px-1.5 py-0.5 rounded bg-orange-50 text-orange-600 cursor-help hover:bg-orange-100"
          >
            <AlertCircle className="h-3.5 w-3.5 inline-block mr-1" />
            {t('interviewQuestions.warnings.complex')}
          </div>
          {activeTooltip === `complex-${questionId}` && (
            <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-3 py-2 bg-gray-900 text-white text-xs rounded whitespace-nowrap">
              <div className="font-medium mb-1">{t('interviewQuestions.warnings.complexTitle')}</div>
              <div className="text-gray-300">{t('interviewQuestions.warnings.complexTip')}</div>
              <div className="mt-1 text-gray-400 italic text-[10px]">{t('interviewQuestions.warnings.complexHelp')}</div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default QuestionWarnings; 