const FUNCTIONS_URL = 'https://tempqswqraeemrlajxrs.functions.supabase.co';

export const createCheckoutSession = async (priceId) => {
  const response = await fetch(`${FUNCTIONS_URL}/create-checkout`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ priceId })
  });

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.message || 'Failed to create checkout session');
  }

  return response.json();
}; 