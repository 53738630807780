import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    supportedLngs: ['en', 'da'],
    debug: true,
    
    backend: {
      loadPath: '/role-designer/locales/{{lng}}/{{ns}}.json',
    },

    detection: {
      order: ['localStorage', 'navigator'],
      caches: ['localStorage'],
      lookupLocalStorage: 'i18nextLng',
    },

    ns: ['common', 'pricing', 'outputs'],
    defaultNS: 'common',
    fallbackNS: 'common',

    interpolation: {
      escapeValue: false
    },

    react: {
      useSuspense: false
    }
  });

// Debug listeners
i18n.on('initialized', (options) => {
  console.log('[i18n] Initialized:', options);
});

i18n.on('loaded', (loaded) => {
  console.log('[i18n] Resources loaded:', loaded);
});

i18n.on('failedLoading', (lng, ns, msg) => {
  console.error('[i18n] Failed loading:', { lng, ns, msg });
});

export default i18n; 