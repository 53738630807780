import React, { useState, useEffect } from 'react';
import { Calendar, Sparkles, Trash2, PlusCircle } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { 
  Card, 
  CardHeader, 
  CardTitle, 
  CardContent, 
  CardSubHeader,
  GradientButton
} from './CardComponents';
import { useFormContext } from '../contexts/FormContext';
import { showToast, TOAST_TYPES } from './common/ToastManager';
import { fetchQuickAddTimeline } from '../utils/fetchQuickAddTimeline';
import { formatDate, calculateDaysFromNow, createInitialTimeline, calculateAdjustedDates } from '../utils/timeline';
import QuickAddPanel from './common/QuickAddPanel';

const TimelineProcess = () => {
  const { t, i18n } = useTranslation();
  const { formData, updateTimelines } = useFormContext();
  const [timelineSteps, setTimelineSteps] = useState(formData.timelines?.steps || []);
  const [quickAddSteps, setQuickAddSteps] = useState([]);
  const [loadingSuggestions, setLoadingSuggestions] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [previousTargetDate, setPreviousTargetDate] = useState(null);
  const [needsDateAdjustment, setNeedsDateAdjustment] = useState(false);
  const [isQuickAddCollapsed, setIsQuickAddCollapsed] = useState(false);
  const [targetStartDate, setTargetStartDate] = useState(formData.timelines?.targetStartDate || '');

  // Consolidated date handling function
  const updateTargetDate = (newDate) => {
    const oldDate = formData.timelines?.targetStartDate;
    
    // Update both target date and steps in one update
    updateTimelines({
      targetStartDate: newDate,
      steps: timelineSteps.map(step => 
        step.text === t('timeline.steps.targetStart')
          ? { ...step, date: newDate }
          : step
      ).sort((a, b) => new Date(a.date) - new Date(b.date))
    });

    // Store previous date for potential adjustments
    setPreviousTargetDate(oldDate);
    setIsExpanded(true);

    // Check if we need date adjustment
    const hasIntermediateSteps = timelineSteps.some(step => 
      step.text !== t('timeline.steps.initialRole') && 
      step.text !== t('timeline.steps.targetStart')
    );

    if (hasIntermediateSteps && oldDate && newDate && oldDate !== newDate) {
      setNeedsDateAdjustment(true);
    }
  };

  // Handler for header date change
  const handleTargetDateChange = (date) => {
    updateTargetDate(date);
  };

  // Handler for list date changes
  const handleDateChange = (index, newDate) => {
    const step = timelineSteps[index];
    
    if (step.text === t('timeline.steps.targetStart')) {
      updateTargetDate(newDate);
    } else {
      const newSteps = [...timelineSteps];
      newSteps[index].date = newDate;
      const sortedSteps = newSteps.sort((a, b) => new Date(a.date) - new Date(b.date));
      setTimelineSteps(sortedSteps);
    }
  };

  // Handler for adjusting all dates
  const handleAdjustAllDates = () => {
    const newDate = formData.timelines?.targetStartDate;
    
    if (!newDate) {
      showToast(t('timeline.toasts.noTargetDate'), TOAST_TYPES.ERROR);
      return;
    }

    try {
      // Calculate adjusted dates
      const adjustedSteps = calculateAdjustedDates(timelineSteps, newDate);
      
      // Update timeline steps with adjusted dates
      setTimelineSteps(adjustedSteps);
      updateTimelines({
        steps: adjustedSteps,
        targetStartDate: newDate
      });
      
      // Reset adjustment flag
      setNeedsDateAdjustment(false);
      
      showToast(t('timeline.toasts.adjustSuccess'), TOAST_TYPES.SUCCESS);
    } catch (error) {
      console.error('Error adjusting dates:', error);
      showToast(t('timeline.toasts.adjustError'), TOAST_TYPES.ERROR);
    }
  };

  const handleDeleteStep = (id) => {
    setTimelineSteps(prev => {
      const newSteps = prev.filter(step => step.id !== id);
      updateTimelines({
        steps: newSteps,
        targetStartDate: formData.timelines?.targetStartDate || ''
      });
      return newSteps;
    });
  };

  const handleAddStep = () => {
    const newStep = {
      id: Date.now(),
      text: '',
      date: '',
      duration: ''
    };
    
    setTimelineSteps(prev => {
      const newSteps = [...prev, newStep];
      updateTimelines({
        steps: newSteps,
        targetStartDate: formData.timelines?.targetStartDate || ''
      });
      return newSteps;
    });
  };

  // Remove redundant effects that were causing circular updates
  useEffect(() => {
    // Only initialize if there are no steps AND no steps in formData
    if ((!timelineSteps || timelineSteps.length === 0) && (!formData.timelines?.steps || formData.timelines.steps.length === 0)) {
      try {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const initialTimeline = createInitialTimeline(
          today.toISOString().split('T')[0],
          formData.timelines?.targetStartDate
        );
        
        if (initialTimeline.length > 0) {
          setTimelineSteps(initialTimeline);
          updateTimelines({
            steps: initialTimeline,
            targetStartDate: formData.timelines?.targetStartDate || ''
          });
        }
      } catch (error) {
        console.error('Error initializing timeline:', error);
      }
    }
  }, []); // Only run on mount

  // Single effect to sync with external changes
  useEffect(() => {
    if (formData.timelines?.steps) {
      const externalSteps = formData.timelines.steps;
      const localSteps = timelineSteps;
      const stepsChanged = JSON.stringify(externalSteps) !== JSON.stringify(localSteps);
      
      if (stepsChanged) {
        setTimelineSteps(externalSteps);
      }
    }
  }, [formData.timelines?.steps]);

  // Remove automatic date adjustment - only adjust when user clicks button
  const handleGenerateSuggestions = async () => {
    if (!formData.timelines?.targetStartDate) {
      showToast(t('timeline.toasts.setDateFirst'), TOAST_TYPES.WARNING);
      return;
    }

    setLoadingSuggestions(true);
    setIsExpanded(true);

    try {
      // Get today's date in user's timezone
      const now = new Date();
      now.setHours(0, 0, 0, 0);
      const today = now.toISOString().split('T')[0];
      
      // Use existing steps, only create initial timeline if none exist
      let currentSteps = timelineSteps;
      if (!currentSteps || currentSteps.length === 0) {
        currentSteps = createInitialTimeline(today, formData.timelines.targetStartDate);
        setTimelineSteps(currentSteps);
        updateTimelines({
          steps: currentSteps,
          targetStartDate: formData.timelines?.targetStartDate || ''
        });
      }
      
      // Get quick add suggestions based on current steps
      const suggestions = await fetchQuickAddTimeline({
        targetDate: formData.timelines.targetStartDate,
        existingSteps: currentSteps,
        language: i18n.language,
        referenceDate: today
      });

      const formattedSuggestions = suggestions.map((s) => ({
        label: s.Suggestions,
        date: s.timeframe,
        duration: s.duration || ''
      }));

      // Only update quick add suggestions, not the main timeline
      setQuickAddSteps(formattedSuggestions);
      showToast(t('timeline.toasts.suggestionsSuccess'), TOAST_TYPES.SUCCESS);
    } catch (error) {
      console.error('Error generating suggestions:', error);
      showToast(t('timeline.toasts.suggestionsError'), TOAST_TYPES.ERROR);
    } finally {
      setLoadingSuggestions(false);
    }
  };

  const handleAddQuickStep = (step) => {
    const newStep = {
      id: Date.now(),
      text: step.label,
      date: step.date,
      duration: step.duration
    };

    setTimelineSteps(prev => {
      const newSteps = [...prev];
      const insertIndex = newSteps.findIndex(s => new Date(s.date) > new Date(step.date));
      if (insertIndex === -1) {
        newSteps.push(newStep);
      } else {
        newSteps.splice(insertIndex, 0, newStep);
      }
      const sortedSteps = newSteps.sort((a, b) => new Date(a.date) - new Date(b.date));
      updateTimelines({
        steps: sortedSteps,
        targetStartDate: formData.timelines?.targetStartDate || ''
      });
      return sortedSteps;
    });

    setQuickAddSteps(prev => prev.filter(s => s.label !== step.label));
  };

  return (
    <div className="max-w-5xl mx-auto space-y-4">
      <Card className="max-w-5xl mx-auto">
        <CardHeader 
          data={formData.timelines}
          requiredFields={['steps']}
          isExpanded={isExpanded}
          onClick={() => setIsExpanded(!isExpanded)}
          inputSection={
            <div className="flex items-center gap-2">
              <div className="flex items-center gap-2 relative w-[250px]">
                <input
                  type="date"
                  value={formData.timelines?.targetStartDate || ''}
                  onChange={(e) => handleTargetDateChange(e.target.value)}
                  className="w-full p-2 border rounded-md"
                  onClick={(e) => e.stopPropagation()}
                />
              </div>
              <GradientButton
                onClick={(e) => {
                  e.stopPropagation();
                  handleGenerateSuggestions();
                }}
                disabled={loadingSuggestions}
                icon={Sparkles}
                loadingText={t('timeline.generating')}
                isLoading={loadingSuggestions}
                className="!bg-gradient-to-r !from-blue-600 !to-blue-500 hover:!from-blue-700 hover:!to-blue-600 !px-3"
              >
                {t('timeline.generateButton')}
              </GradientButton>
            </div>
          }
        >
          <div className="flex items-center gap-2">
            <Calendar className="h-5 w-5" />
            <div>
              <CardTitle>{t('timeline.title')}</CardTitle>
              <CardSubHeader>
                {formData.timelines?.steps?.length > 0 ? (
                  <span>
                    {t('timeline.targetStart')}: {formatDate(formData.timelines?.targetStartDate)}
                  </span>
                ) : (
                  t('timeline.setTargetDate')
                )}
              </CardSubHeader>
            </div>
          </div>
        </CardHeader>

        {isExpanded && (
          <CardContent className="space-y-6">
            <div className={`grid ${isQuickAddCollapsed ? 'grid-cols-1' : 'grid-cols-3'} gap-6`}>
              <div className={isQuickAddCollapsed ? 'col-span-1' : 'col-span-2'}>
                {needsDateAdjustment && (
                  <div className="mb-4 p-3 bg-yellow-50 border border-yellow-200 rounded-lg flex items-center justify-between">
                    <span className="text-sm text-yellow-800">
                      {t('timeline.adjustDatesPrompt')}
                    </span>
                    <button
                      onClick={handleAdjustAllDates}
                      className="px-3 py-1 bg-yellow-100 text-yellow-800 rounded-md text-sm hover:bg-yellow-200 transition-colors"
                    >
                      {t('timeline.adjustDatesButton')}
                    </button>
                  </div>
                )}
                <div className="space-y-3">
                  <h3 className="text-sm font-medium text-gray-700 mb-2">{t('timeline.timelineSteps')}</h3>
                  <div className="space-y-3">
                    {timelineSteps.map((step, index) => (
                      <div key={step.id} className="flex flex-col gap-1 p-3 border rounded-lg bg-white">
                        <div className="flex items-start gap-4">
                          <div className="flex-grow min-w-0">
                            <input
                              type="text"
                              value={step.text}
                              onChange={(e) => {
                                const newSteps = [...timelineSteps];
                                newSteps[index].text = e.target.value;
                                setTimelineSteps(newSteps);
                              }}
                              className="w-full p-2 border border-gray-300 rounded-md text-xs"
                              placeholder={t('timeline.stepPlaceholder')}
                            />
                          </div>
                          <div className="flex items-center gap-2 flex-shrink-0">
                            <input
                              type="date"
                              value={step.date || ''}
                              onChange={(e) => handleDateChange(index, e.target.value)}
                              className="w-32 p-2 border rounded-md text-sm"
                            />
                            <button
                              onClick={() => handleDeleteStep(step.id)}
                              className="p-1 rounded hover:bg-red-100"
                            >
                              <Trash2 className="h-4 w-4 text-red-600" />
                            </button>
                          </div>
                        </div>
                        {step.date && (
                          <p className="text-xs text-gray-500">
                            {calculateDaysFromNow(step.date)}
                            {step.duration && ` • ${step.duration}`}
                          </p>
                        )}
                      </div>
                    ))}
                  </div>
                  <button
                    onClick={handleAddStep}
                    className="flex items-center gap-1.5 text-blue-600 hover:text-blue-800 mt-4 text-sm"
                  >
                    <PlusCircle className="h-4 w-4" />
                    {t('timeline.addStepButton')}
                  </button>
                </div>
              </div>

              <QuickAddPanel
                isCollapsed={isQuickAddCollapsed}
                onCollapse={setIsQuickAddCollapsed}
                isLoading={loadingSuggestions}
                items={quickAddSteps}
                onItemClick={handleAddQuickStep}
                onReload={handleGenerateSuggestions}
                translationPrefix="quickAdd"
                renderItem={(step, index) => (
                  <button
                    key={index}
                    onClick={() => handleAddQuickStep(step)}
                    className="w-full text-left flex flex-col gap-1 p-2 rounded border border-gray-200 bg-white hover:border-blue-500 hover:bg-blue-50 transition-colors"
                  >
                    <span className="font-medium text-sm">{step.label}</span>
                    <span className="text-xs text-gray-500">
                      {formatDate(step.date)} ({calculateDaysFromNow(step.date)})
                    </span>
                    {step.duration && (
                      <span className="text-xs text-gray-400">{t('timeline.quickAdd.duration')} {step.duration}</span>
                    )}
                  </button>
                )}
              />
            </div>
          </CardContent>
        )}
      </Card>
    </div>
  );
};

export default TimelineProcess;
