import i18next from 'i18next';

/**
 * Basic date calculations
 */
export const calculateDaysDifference = (date1, date2) => {
  const d1 = new Date(date1);
  const d2 = new Date(date2);
  return Math.floor((d2 - d1) / (1000 * 60 * 60 * 24));
};

/**
 * Date formatting
 */
export const formatDate = (date) => {
  if (!date) return '';
  const language = i18next.language || 'en';
  return new Date(date).toLocaleDateString(language === 'da' ? 'da-DK' : 'en-US', {
    weekday: 'short',
    month: 'short',
    day: 'numeric'
  });
};

export const calculateDaysFromNow = (date) => {
  if (!date) return '';
  const t = i18next.getFixedT(null);
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const targetDate = new Date(date);
  targetDate.setHours(0, 0, 0, 0);
  
  const days = Math.floor((targetDate - today) / (1000 * 60 * 60 * 24));
  
  if (days === 0) return t('timeline.dates.today');
  if (days === 1) return t('timeline.dates.tomorrow');
  if (days === -1) return t('timeline.dates.yesterday');
  if (days < 0) return t('timeline.dates.daysAgo', { count: Math.abs(days) });
  return t('timeline.dates.inDays', { count: days });
};

/**
 * Timeline generation and adjustments
 */
export const createInitialTimeline = (startDate, targetDate) => {
  try {
    const t = i18next.getFixedT(null);
    
    // If no dates provided, use today and 30 days from now
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    
    const roleDefinitionDate = startDate ? new Date(startDate) : today;
    const endDate = targetDate ? new Date(targetDate) : new Date(today.setDate(today.getDate() + 30));

    // Validate dates
    if (isNaN(roleDefinitionDate.getTime()) || isNaN(endDate.getTime())) {
      console.warn('Invalid dates in createInitialTimeline, using fallback dates');
      return [];
    }

    // Ensure dates are properly ordered
    if (roleDefinitionDate > endDate) {
      console.warn('Start date is after end date in createInitialTimeline');
      return [];
    }

    const totalDays = Math.max(1, Math.floor((endDate - roleDefinitionDate) / (1000 * 60 * 60 * 24)));
    
    // Place screening at 30% of the time between today and target date
    const screeningDeadline = new Date(roleDefinitionDate);
    screeningDeadline.setDate(roleDefinitionDate.getDate() + Math.floor(totalDays * 0.3));
    
    // Place assessment at 60% of the time between today and target date
    const assessmentDeadline = new Date(roleDefinitionDate);
    assessmentDeadline.setDate(roleDefinitionDate.getDate() + Math.floor(totalDays * 0.6));
    
    const steps = [
      {
        id: Date.now(),
        text: t('timeline.steps.initialRole'),
        date: roleDefinitionDate.toISOString().split('T')[0]
      },
      {
        id: Date.now() + 1,
        text: t('timeline.steps.screening'),
        date: screeningDeadline.toISOString().split('T')[0]
      },
      {
        id: Date.now() + 2,
        text: t('timeline.steps.assessment'),
        date: assessmentDeadline.toISOString().split('T')[0]
      },
      {
        id: Date.now() + 3,
        text: t('timeline.steps.targetStart'),
        date: endDate.toISOString().split('T')[0]
      }
    ];

    return steps.sort((a, b) => new Date(a.date) - new Date(b.date));
  } catch (error) {
    console.error('Error in createInitialTimeline:', error);
    return [];
  }
};

export const calculateAdjustedDates = (steps, targetDate) => {
  if (!steps || !Array.isArray(steps) || steps.length === 0 || !targetDate) {
    return steps;
  }

  try {
    const t = i18next.getFixedT(null);
    const initialStep = steps.find(step => step.text === t('timeline.steps.initialRole'));
    if (!initialStep || !initialStep.date) {
      return steps;
    }

    const startDate = new Date(initialStep.date);
    const endDate = new Date(targetDate);

    // Validate dates
    if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
      console.warn('Invalid date detected in calculateAdjustedDates');
      return steps;
    }

    // Calculate total duration using existing function
    const totalDays = calculateDaysDifference(startDate, endDate);
    if (totalDays <= 0) {
      console.warn('Invalid date range in calculateAdjustedDates');
      return steps;
    }

    // Get middle steps (excluding initial and target)
    const middleSteps = steps.filter(step => 
      step.text !== t('timeline.steps.initialRole') && 
      step.text !== t('timeline.steps.targetStart')
    );

    // Calculate even spacing for middle steps
    const numIntervals = middleSteps.length + 1; // +1 because we need one more interval than steps
    const intervalDays = Math.floor(totalDays / numIntervals);

    // Create a map of adjusted dates for middle steps
    const middleDates = middleSteps.map((_, index) => {
      const newDate = new Date(startDate);
      newDate.setDate(startDate.getDate() + ((index + 1) * intervalDays));
      return newDate.toISOString().split('T')[0];
    });

    // Map all steps to their new dates
    return steps.map(step => {
      // Keep Initial Role Definition date unchanged
      if (step.text === t('timeline.steps.initialRole')) return step;
      
      // Set Target Candidate Start Date to the new target date
      if (step.text === t('timeline.steps.targetStart')) {
        return { ...step, date: targetDate };
      }

      // Find the corresponding middle date for this step
      const middleIndex = middleSteps.findIndex(s => s.text === step.text);
      if (middleIndex !== -1) {
        return { ...step, date: middleDates[middleIndex] };
      }

      return step;
    }).sort((a, b) => new Date(a.date) - new Date(b.date));
  } catch (error) {
    console.error('Error in calculateAdjustedDates:', error);
    return steps;
  }
}; 