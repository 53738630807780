import React, { useState, useEffect } from 'react';
import { 
  Blocks, 
  Sparkles, 
  Trash2, 
  PlusCircle,
  ArrowRight,
  ChevronUp,
  ChevronDown
} from 'lucide-react';
import { 
  Card, 
  CardHeader, 
  CardTitle, 
  CardContent, 
  CardSubHeader,
  GradientButton 
} from './CardComponents';
import { fetchQuickAddsResponsibilities } from '../utils/fetchQuickAddsResponsibilities';
import { useFormContext } from '../contexts/FormContext';
import { showToast, TOAST_TYPES } from './common/ToastManager';
import QuickAddPanel from './common/QuickAddPanel';
import { useTranslation } from 'react-i18next';

// Add this at the top of the file
const generateUniqueId = () => {
  return `resp_${Math.random().toString(36).substr(2, 9)}_${Date.now()}`;
};

const Responsibilities = () => {
  const { formData, updateResponsibilities } = useFormContext();
  const [isLoading, setIsLoading] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [availableQuickAdds, setAvailableQuickAdds] = useState([]);
  const [isQuickAddCollapsed, setIsQuickAddCollapsed] = useState(false);
  const { t } = useTranslation();

  // Initialize responsibilities from formData only once on mount
  const [responsibilities, setResponsibilities] = useState(formData.responsibilities || []);

  // Update form context when responsibilities change
  useEffect(() => {
    updateResponsibilities(responsibilities);
  }, [responsibilities]);

  const handleGenerateResponsibilities = async () => {
    setIsLoading(true);
    try {
      console.log('Starting responsibility generation with formData:', {
        companyName: formData.companyData?.name,
        jobTitle: formData.roleData?.jobTitle,
        currentResponsibilitiesCount: responsibilities.length
      });

      showToast(t('responsibilities.toasts.generating'), TOAST_TYPES.INFO);
      const newResponsibilities = await fetchQuickAddsResponsibilities(
        formData, 
        true,
        [...responsibilities, ...availableQuickAdds]
      );
      
      console.log('Received new responsibilities:', {
        count: newResponsibilities?.length,
        isArray: Array.isArray(newResponsibilities),
        sample: newResponsibilities?.[0]
      });

      if (Array.isArray(newResponsibilities) && newResponsibilities.length > 0) {
        // Only add new responsibilities, don't replace existing ones
        setResponsibilities((prev) => {
          const newItems = newResponsibilities.map(text => ({ 
            id: generateUniqueId(),
            text 
          }));
          return [...prev, ...newItems];
        });
        showToast(t('responsibilities.toasts.success'), TOAST_TYPES.SUCCESS);
        setIsExpanded(true);
      } else {
        console.warn('Invalid responsibilities response:', newResponsibilities);
        showToast(t('responsibilities.toasts.error'), TOAST_TYPES.ERROR);
      }
    } catch (error) {
      console.error('Error generating responsibilities:', {
        name: error.name,
        message: error.message,
        stack: error.stack
      });
      showToast(t('responsibilities.toasts.error'), TOAST_TYPES.ERROR);
    } finally {
      setIsLoading(false);
    }
  };

  const handleReloadSuggestions = async () => {
    setIsLoading(true);
    try {
      const suggestions = await fetchQuickAddsResponsibilities(
        formData, 
        true,
        [...responsibilities, ...availableQuickAdds]
      );
      if (Array.isArray(suggestions)) {
        setAvailableQuickAdds(suggestions);
        showToast(t('responsibilities.toasts.reloadSuccess'), TOAST_TYPES.SUCCESS);
      }
    } catch (error) {
      showToast(t('responsibilities.toasts.error'), TOAST_TYPES.ERROR);
    } finally {
      setIsLoading(false);
    }
  };

  const addResponsibilityFromQuickAdd = (text) => {
    setResponsibilities(prev => [...prev, { 
      id: generateUniqueId(),
      text 
    }]);
    setAvailableQuickAdds(prev => prev.filter(item => item !== text));
  };

  const handleAddResponsibility = () => {
    setResponsibilities(prev => [
      ...prev, 
      { 
        id: generateUniqueId(),
        text: '' 
      }
    ]);
  };

  const updateResponsibilityText = (id, text) => {
    setResponsibilities(prev =>
      prev.map(item => (item.id === id ? { ...item, text } : item))
    );
  };

  const deleteResponsibility = (id) => {
    setResponsibilities(prev => prev.filter(item => item.id !== id));
  };

  const reorderResponsibility = (index, direction) => {
    const newList = [...responsibilities];
    const [movedItem] = newList.splice(index, 1);
    newList.splice(index + direction, 0, movedItem);
    setResponsibilities(newList);
  };

  return (
    <Card className="max-w-5xl mx-auto">
      <CardHeader 
        data={{ responsibilities }}
        requiredFields={['responsibilities']}
        isExpanded={isExpanded}
        onClick={() => setIsExpanded(!isExpanded)}
        inputSection={
          <div className="flex items-center gap-2">
            <GradientButton
              onClick={(e) => {
                e.stopPropagation();
                handleGenerateResponsibilities();
              }}
              disabled={isLoading}
              icon={Sparkles}
              loadingText={t('responsibilities.buttons.generating')}
              isLoading={isLoading}
              className="!bg-gradient-to-r !from-blue-600 !to-blue-500 hover:!from-blue-700 hover:!to-blue-600 !px-3"
            >
              {t('responsibilities.buttons.generate')}
            </GradientButton>
          </div>
        }
      >
        <div className="flex items-center gap-2">
          <Blocks className="h-5 w-5" />
          <div>
            <CardTitle>{t('responsibilities.title')}</CardTitle>
            <CardSubHeader 
              count={responsibilities.length || undefined}
              emptyText={t('responsibilities.emptyText')}
            >
              {t('responsibilities.subtitle')}
            </CardSubHeader>
          </div>
        </div>
      </CardHeader>

      {isExpanded && (
        <CardContent className="space-y-6">
          <div className={`grid ${isQuickAddCollapsed ? 'grid-cols-1' : 'grid-cols-3'} gap-6`}>
            {/* Left Column: Responsibilities List */}
            <div className={isQuickAddCollapsed ? 'col-span-1' : 'col-span-2'}>
              <div className="space-y-2">
                {responsibilities.map((item, index) => (
                  <div key={item.id} className="flex items-center gap-3">
                    <input
                      type="text"
                      className="flex-1 p-2 border border-gray-300 rounded-md text-xs"
                      value={item.text}
                      onChange={(e) => updateResponsibilityText(item.id, e.target.value)}
                    />
                    <div className="flex items-center gap-1">
                      <div className="flex flex-col">
                        <button
                          onClick={() => reorderResponsibility(index, -1)}
                          disabled={index === 0}
                          className="p-0.5 rounded hover:bg-gray-100 disabled:opacity-50 disabled:hover:bg-transparent"
                        >
                          <ChevronUp className="h-3.5 w-3.5 text-gray-600" />
                        </button>
                        <button
                          onClick={() => reorderResponsibility(index, 1)}
                          disabled={index === responsibilities.length - 1}
                          className="p-0.5 rounded hover:bg-gray-100 disabled:opacity-50 disabled:hover:bg-transparent"
                        >
                          <ChevronDown className="h-3.5 w-3.5 text-gray-600" />
                        </button>
                      </div>
                      <button
                        onClick={() => deleteResponsibility(item.id)}
                        className="p-1 rounded hover:bg-red-100"
                      >
                        <Trash2 className="h-4 w-4 text-red-600" />
                      </button>
                    </div>
                  </div>
                ))}
              </div>

              <button
                onClick={handleAddResponsibility}
                className="flex items-center gap-1.5 text-blue-600 hover:text-blue-800 mt-4 text-sm"
              >
                <PlusCircle className="h-4 w-4" />
                {t('responsibilities.buttons.add')}
              </button>
            </div>

            {/* Replace the existing Quick Add section with QuickAddPanel */}
            <QuickAddPanel
              isCollapsed={isQuickAddCollapsed}
              onCollapse={setIsQuickAddCollapsed}
              isLoading={isLoading}
              items={availableQuickAdds}
              onItemClick={addResponsibilityFromQuickAdd}
              onReload={handleReloadSuggestions}
              translationPrefix="quickAdd"
              renderItem={(example, index) => (
                <button
                  key={index}
                  onClick={() => addResponsibilityFromQuickAdd(example)}
                  className="w-full flex items-center gap-2 p-2 bg-white rounded border border-gray-200 text-xs hover:border-blue-500 hover:bg-blue-50 transition-colors"
                >
                  <ArrowRight className="h-3 w-3 text-gray-400 flex-shrink-0" />
                  <span className="text-gray-600 text-left">{example}</span>
                </button>
              )}
            />
          </div>
        </CardContent>
      )}
    </Card>
  );
};

export default Responsibilities;
