import React, { useState } from 'react';
import { CheckCircle2 } from 'lucide-react';
import { loadStripe } from '@stripe/stripe-js';
import { STRIPE_PRICE_IDS, PLANS } from './config/plans.ts';
import { createCheckoutSession } from '../../services/stripe';

const stripeKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
if (!stripeKey) {
  throw new Error('Stripe publishable key not found');
}

const stripePromise = loadStripe(stripeKey);

const BillingToggle = ({ isAnnual, onToggle }) => (
  <div className="flex justify-center items-center space-x-3 mb-8">
    <span className={`text-sm ${!isAnnual ? 'font-semibold text-blue-600' : 'text-gray-500'}`}>
      Monthly
    </span>
    <button
      onClick={onToggle}
      className="relative inline-flex h-6 w-11 items-center rounded-full bg-gray-200"
    >
      <span className="sr-only">Toggle billing period</span>
      <span
        className={`inline-block h-4 w-4 transform rounded-full bg-white transition ${
          isAnnual ? 'translate-x-6' : 'translate-x-1'
        }`}
      />
    </button>
    <div className="flex items-center space-x-2">
      <span className={`text-sm ${isAnnual ? 'font-semibold text-blue-600' : 'text-gray-500'}`}>
        Annual
      </span>
      <span className="text-xs font-medium text-green-500 bg-green-100 px-2 py-0.5 rounded-full">
        Save up to 37%
      </span>
    </div>
  </div>
);

const PlanCard = ({ plan, isAnnual }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleCheckout = async () => {
    setIsLoading(true);
    try {
      console.log('Starting checkout for plan:', plan.id);
      
      const stripe = await stripePromise;
      if (!stripe) {
        throw new Error('Failed to initialize Stripe');
      }

      const priceId = STRIPE_PRICE_IDS[isAnnual ? 'annual' : 'monthly'][plan.id];
      console.log('Using price ID:', priceId);

      const { sessionId } = await createCheckoutSession(priceId);
      console.log('Got session ID:', sessionId);
      
      const result = await stripe.redirectToCheckout({ sessionId });
      
      if (result.error) {
        throw new Error(result.error.message);
      }
    } catch (error) {
      console.error('Checkout error:', error);
      alert(`Checkout failed: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col p-6 bg-white rounded-lg shadow-md border border-gray-200">
      <h3 className="text-xl font-semibold text-gray-900">{plan.name}</h3>
      <div className="mt-4">
        <span className="text-4xl font-bold">${plan.price}</span>
        <span className="text-gray-500">/{isAnnual ? 'year' : 'month'}</span>
      </div>
      <p className="mt-4 text-gray-600">{plan.description}</p>
      <ul className="mt-6 space-y-4 flex-1">
        {plan.features.map((feature, index) => (
          <li key={index} className="flex items-center">
            <CheckCircle2 className="h-5 w-5 text-green-500 mr-2" />
            <span className="text-gray-600">{feature}</span>
          </li>
        ))}
      </ul>
      <button 
        className={`mt-8 w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 transition-colors
          ${isLoading ? 'opacity-75 cursor-not-allowed' : ''}`}
        onClick={handleCheckout}
        disabled={isLoading}
      >
        {isLoading ? 'Processing...' : 'Get Started'}
      </button>
    </div>
  );
};

const PricingPlans = () => {
  const [isAnnual, setIsAnnual] = useState(false);
  const currentPlans = isAnnual ? PLANS.annual : PLANS.monthly;

  return (
    <div className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <div className="text-center">
          <h2 className="text-3xl font-bold text-gray-900 sm:text-4xl">
            Simple, transparent pricing
          </h2>
          <p className="mt-4 text-lg text-gray-600">
            Choose the perfect plan for your needs
          </p>
        </div>
        
        <div className="mt-12">
          <BillingToggle 
            isAnnual={isAnnual} 
            onToggle={() => setIsAnnual(!isAnnual)} 
          />
          
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:max-w-4xl lg:mx-auto">
            {currentPlans.map((plan) => (
              <PlanCard 
                key={plan.id} 
                plan={plan} 
                isAnnual={isAnnual}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingPlans; 